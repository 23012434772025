import { Box, Button, Dialog, DialogContent, DialogTitle, LinearProgress, Table, TableBody, TableCell, TableRow } from '@mui/material'
import React from 'react'

interface IBuildStatusDialog {
  build : any,
  onClose: () => void,
}

const BuildStatusDialog = (props: IBuildStatusDialog) => {
  return (
    <Dialog fullWidth open={props.build !== null} onClose={()=>props.onClose()}>
      <DialogTitle>Build Status</DialogTitle>
      {
        props.build !== null && <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Progress</TableCell>
                <TableCell> 
                  <Box sx={{width:'100%',display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                    <Box sx={{width:'70%'}}><LinearProgress variant="determinate" value={props.build.progress} /></Box>
                    <Box sx={{marginLeft:'5px',width:'30%'}}>({props.build.current_line} / {props.build.total_lines})</Box>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>{props.build.status}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {props.build.progress === 100 && <Box sx={{m:3}} ><Button variant="contained" onClick={()=>props.onClose()}> Close Box </Button></Box>}
        </DialogContent>
      }
    </Dialog>
  )
}

export default BuildStatusDialog