import React, {MutableRefObject, useState} from 'react'
import { Box, Button, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { toast } from 'react-toastify'
import { Grid } from '@syncfusion/ej2-react-grids'
import LoadingButton from '@mui/lab/LoadingButton';
import parseHandlerFunction from '../../../../../components/ParseHandler/parseHandlerFunction'

const FileCacheTableView = (props:{fi_name: string, fi_srcpath:string, fi_params:string, tableRef: MutableRefObject<Grid>}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const regenerateCache = async (e:any) => {
    e.stopPropagation();
    setLoading(true)
    const parse = await parseHandlerFunction(props.fi_name)
    // const id = toast.loading('Regenerating cache...', {isLoading: true})

    // let res = await POST('file/index.php', {action: 'REGENERATE_CACHE', fileName: props.fi_name})
    // while (res.data.status === false) {
    //   res = await POST('file/index.php', {action: 'REGENERATE_CACHE', fileName: props.fi_name})
    //   if (res.data.status === false) {
    //     toast.update(id,{
    //       render: `Currently Line ${res.data?.data?.current_line ?? 0} / ${res.data?.data?.total_lines ?? 0}!`,
    //       type: 'info',
    //       isLoading: true,
    //       autoClose: false,
    //     })
    //   }
    // }
    
    
    if (parse) {
      // console.log("Tableref",props.tableRef)
      if (props.tableRef.current !== null) {
        props.tableRef.current.refresh()
        setLoading(false)
      }
      // toast.update(id, {
      //   render:  'Cache re-generated!',
      //   type: 'success',
      //   isLoading: false,
      //   autoClose: 3000
      // })
    } else {
      toast.error('Error re-generating cache')
      setLoading(false)
    }
  }

  const generateTable = () => {
    const generateJSON = ():{[key:string] : {value: any, unit ?: string}}  => {
      let data:any = {}
      props.fi_params.split('***').forEach((item:string) => {
        const _item = item.split('|')
        // console.log(`item : isunit ? ${_item[0].toLowerCase().includes('_unit')}`, _item)
        // console.log("current data ",JSON.parse(JSON.stringify(data)))
        // is a unit
        if (_item[0].toLowerCase().includes('_unit')) {
          const _itemKey = _item[0].substring(0,_item[0].lastIndexOf('_'))
          if (data[_itemKey] !== undefined) {data[_itemKey] = {value: data[_itemKey].value, unit: _item[1]}}
          else {data[_itemKey] = {value: null, unit:_item[1]} }
        } else {
          if (data[_item[0]] !== undefined) {data[_item[0]] = {unit : data[_item[0]].unit, value: _item[1]}}
          else {data[_item[0]] = {value: _item[1]} }
        }
      })
      return data
    }

    if (props.fi_params === null) {return <p>Not Cached Yet!</p>}
    
    const data = generateJSON()
    // console.log("GenerateTable - data", data)
    const _keys = Object.keys(data)

    return <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            _keys.map((key:string, index:number) => {
              return <TableRow key={`fctv-${props.fi_name.replaceAll(' ','')}-${index}`}>
                <TableCell>{key} {data[key]?.unit ? `(${data[key].unit})` : ''}</TableCell>
                <TableCell>{`${data[key].value}${(data[key].value ? data[key].value.length >= 50 : false) ? '...' : ''}`}</TableCell>
              </TableRow>
            })
          }
        </TableBody>
      </Table>
    </TableContainer>

    
  }

  return (<div>
    <LoadingButton loading={loading} variant="contained" onClick={(e)=>{e.stopPropagation(); setOpen(true)}}>Show Cached Data</LoadingButton>
    <Dialog maxWidth="lg" fullWidth open={open} onClose={()=>setOpen(false)}>
      <DialogTitle>Actions</DialogTitle>
      <DialogContent>
        <Box>
          <Button variant="contained" onClick={regenerateCache}>Regenerate Cache</Button>
        </Box>
        {generateTable()}
        {/* props.fi_params === null ? <p>Not cached yet!</p> :<Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
            props.fi_params.split('***').map((param:string, index) => {
              const paramSplit = param.split('|')
              return (
                <TableRow key={`fctv-${props.fi_name.replaceAll(' ','')}-${index}`}>
                  <TableCell>{paramSplit[0]}</TableCell>
                  <TableCell>{paramSplit[1] + (paramSplit[1].length >= 50 ? '...' : '')}</TableCell>
                </TableRow>
              )
            })
          }
          </TableBody>
        </Table> */}
      </DialogContent>
    </Dialog>
  </div>)
}

export default FileCacheTableView