import React from 'react'

import Box from "@mui/material/Box/Box"
import Typography from "@mui/material/Typography/Typography"
import Card from "@mui/material/Card/Card"
import Skeleton from '@mui/material/Skeleton';
import CardContent from '@mui/material/CardContent';

interface ICardStats {
  icon ?: React.ReactNode,
  title: string,
  value ?: React.ReactNode,
  color ?: string
}

const CardStats = (props: ICardStats) => {
  return (<Card sx={{ margin:'0.2em', flex:1, minWidth:'300px', backgroundColor: props.color ?? ''}}>
    <CardContent sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
      <Box mr={3}>{props.icon}</Box>
      <Box>
        <Typography fontSize='1.5em'>{props.title}</Typography>
        { props.value !== null ?
          <Typography fontSize='0.8em'>{props.value}</Typography> : 
          <Skeleton variant="text" sx={{ fontSize: '0.8em' }} />  
        }
      </Box>
    </CardContent>
  </Card>)
}

export default CardStats