import { Box,  Dialog, DialogContent, DialogTitle, } from '@mui/material'
import React, {  useState } from 'react'

import { TFileTable } from '../..'
// import { POST } from '../../../../../components/utils/fetch'
// import ParseFile from './components/ParseFile';
import { TOlgaFiles } from '../../../../../interfaces/files';
// import { toast } from 'react-toastify';
// import StringHighlighter from './components/StringHighlighter';
// import { UserContext } from '../../../../../App';
// import LinearProgress from '@mui/material/LinearProgress';
import BuildStatusDialog from './components/BuildStatus';
import RawFileViewer from './components/RawFileViewer';
// import JSONViewer from './components/JSONViewer';

interface IFileModal {
  activeFile : TFileTable | null,
  onHide : () => void,
  fileType : TOlgaFiles
}

const FileModal = (props: IFileModal) => {
  // const {user} = useContext(UserContext)
  // const [selectedText, setSelectedText] = useState<null|string>(null)
  // const [file, setFile] = useState<{raw:string,parsed:any}|null>(null)

  const [build, setBuild] = useState<any|null>(null)

  // useEffect(()=>{
  //   if (props.activeFile !== null) {
  //     const fileName = props.activeFile.fi_srcpath.split('/').pop()
  //     POST(`file/index.php`,{action:'FILE_CHECK_JSON',file_name:fileName, u_id : user === null ? 0 : user.u_id})
  //     .then(async(res) => {        
  //       if (res.data.status) {
  //         toast.success('File found, Parsing and Loading....')

  //         let rawdata = POST<string>(`file/uploads/${props.fileType}/` + encodeURIComponent(fileName === undefined ? '' : fileName),'',false,'string')
  //         let parseddata = POST(`file/json/${props.fileType}/${encodeURIComponent(fileName === undefined ? '' : fileName.split('.').slice(0,-1).join('.'))}.json`,'',false,'json')
  //         Promise.all([rawdata, parseddata])
  //         .then(x => {
  //           setFile({raw:x[0].data, parsed: x[1].data})
  //         })
  //       } else {
  //         if (res.data.data === 'FILE_MISSING') {
  //           toast.error("File Not Present!")
  //         } else if (res.data.data === 'JSON_MISSING') {
  //           const fp_id =  res.data.fp_id
  //           POST(`file/index.php`,{action:'BUILD_JSON',file:fileName, id : fp_id??0},true,'string')
  //           .then(async ()=>{
  //             setBuild(null)
  //             let rawdata = POST<string>(`file/uploads/${props.fileType}/` + encodeURIComponent(fileName === undefined ? '' : fileName),'',false,'string')
  //             let parseddata = POST(`file/json/${props.fileType}/${encodeURIComponent(fileName === undefined ? '' : fileName.split('.').slice(0,-1).join('.'))}.json`,'',false,'json')
  //             Promise.all([rawdata, parseddata])
  //             .then(x => {
  //               setFile({raw:x[0].data, parsed: x[1].data})
  //             })
  //           })

  //           toast.error('JSON File Missing. Rebuilding....')
  //           setBuild({progress: 0, status:'Starting Build'})
  //           let prog = 0
  //           while (prog < 100 || build !== null) {
  //             const data = await POST(`file/index.php`,{action:'CHECK_BUILD_PROGRESS', lastProg: prog, id : fp_id??0})
  //             if (data.data.status) {
  //               prog = Number(data.data.data.progress)
  //               setBuild({progress : Number(data.data.data.progress), status : data.data.data.status, current_line : data.data.data.current_line, total_lines : data.data.data.total_lines})
  //             } else {
  //               prog = 1000
  //               // console.log('[error]- ',data)
  //               toast.error('Something went Wrong!')
  //             }
  //           }
  //         }
  //       }
  //     })      
  //   } else {
  //     setFile(null)
  //   }
  // },[props.activeFile, props.fileType, user, build]) 
  

  return (<div>
    <BuildStatusDialog build={build} onClose={()=>setBuild(null)} />
    <Dialog fullScreen fullWidth open={props.activeFile !== null} onClose={()=>props.onHide()}>
      {props.activeFile !== null && <DialogTitle>File Viewer : {props.activeFile.fi_name}</DialogTitle>}
      <DialogContent>
        <Box sx={{display:'flex',width:'100%',height:'100%'}}>
          <RawFileViewer file={props.activeFile} />
          {/* <Box sx={{width:'50%',height:'100%', overflowX:'auto', p:1, backgroundColor:'gainsboro',fontSize:9}}>
            <Box sx={{backgroundColor:'white', whiteSpace:'pre-wrap'}}>{file === null ? 'Loading...' : <StringHighlighter raw={file.raw} selectedText={selectedText} />}</Box>
          </Box> */}
          {/* <JSONViewer file={props.activeFile} /> */}
          {/* <Box sx={{width:'100%',height:'100%', overflowX:'auto', p:1, backgroundColor:'gainsboro',fontSize:9}}>
            <Box sx={{backgroundColor:'white'}}>{file === null ? 'No Loaded File!' : <ParseFile setSelectedText={setSelectedText} activeFile={file.parsed} fileType={props.fileType} />}</Box>
          </Box> */}
        </Box>
      </DialogContent>
    </Dialog>
  </div>)
}

export default FileModal