import React from 'react'
import Box from "@mui/material/Box/Box"
import MLVersionView from './components/MLVersionView'
import { TMLMain } from '../../../..'

const ViewMLDetails = (props:TMLMain) => {
  // useEffect(()=>{
  //   POST('ml/learning.php',{action: 'GET_SETS', id: props.ftl_id})
  //   .then(response => {
  //     if (response.status) {
  //       setData(response.data)
  //     }
  //   })
  // },[props.ftl_id])

  return (
    <Box mt={5} display={'flex'} alignItems='center' justifyContent={'center'} flexDirection={'column'}>
      <MLVersionView {...props} />
    </Box>
  )
}

export default ViewMLDetails