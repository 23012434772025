import React, { useContext, useRef } from 'react'
import { AugTable } from '../../../components/Table'
import { Grid } from '@syncfusion/ej2-react-grids'
import { UserContext } from '../../../App'
import JobDetails from './components/JobDetails'

const ViewJobs = () => {
  const {user} = useContext(UserContext)
  const tableRef = useRef<Grid>(null)

  // const resizeTable = () => {
  //   if (tableRef.current !== undefined && tableRef.current !== null) {
  //     tableRef.current.autoFitColumns([])
  //   }
  // }

  return (
    <AugTable 
      // dataBound={resizeTable}
      ref={tableRef}
      detailsTemplate={JobDetails}
      allowResizing
      dataManagerUrl={{url:`job/index.php`}}
      query={[
        {param:'action', value:'VIEW_JOBS'},
        {param:'u_id', value: user?.u_id.toString()  ?? '0'}
      ]}
      columns={[
        {width:"200px",field: 'j_name', headerText:'Job Name'},
        {width:"100px",field: 'j_learn', headerText:'No of Learning Indexes'},
        {width:"100px",field: 'j_est', headerText:'No of Estimation Indexes'},
        {width:"600px",field: 'j_description', headerText:'Description', disableHtmlEncode: false},
      ]}  
    />
  )
}

export default ViewJobs