import React, { useEffect, useState } from 'react'
import { TFileTable } from '../../../..'
import { POST } from '../../../../../../../components/utils/fetch'
import { toast } from 'react-toastify'
import { Box, Button } from '@mui/material'

const RawFileViewer = (props: {file: TFileTable|null}) => {
  const [file, setFile] = useState<string>('')
  const [fileIdx, setFileIdx] = useState(0)

  useEffect(()=>{
    if (props.file !== null && fileIdx === 0) {
      // Get First part of file
      POST('file/index.php', {action: 'GET_FILE_RAW', fileName: props.file?.fi_name, index: fileIdx})
      .then(response => {
        if (response.status) {
          // toast.success('File loaded successfully!')
          setFile(response.data.file)
          setFileIdx(response.data.index)
        } else {
          toast.error('An error occurred!')
          // console.log(response)
        }
      })
    }
    
    return () => {setFile(''); setFileIdx(0)}
  },[props.file]) //eslint-disable-line 

  const loadMore = () => {
    if (props.file !== null && fileIdx > 0) {
      toast.info('Loading file contents...')
      POST('file/index.php', {action: 'GET_FILE_RAW', fileName: props.file?.fi_name, index: fileIdx})
      .then(response => {
        if (response.status) {
          // toast.success('File loaded successfully!')
          setFile(prev => `${prev}${response.data.file}`)
          setFileIdx(response.data.index)
        } else {
          toast.error('An error occurred!')
          // console.log(response)
        }
      })
    }
  }


  return (
    <Box sx={{height:'100%',width:'100%', overflowY:'scroll', overflowX:'auto', p:1, backgroundColor:'gainsboro',fontSize:9}}>
      <Box sx={{backgroundColor:'white', whiteSpace:'pre-wrap'}}>
        {file === '' ? 'Loading...' : file}
        {fileIdx > 0 && <Box sx={{display:'flex',justifyContent:'center'}}><Button variant="contained" onClick={()=>loadMore()}>Load More...</Button></Box>}
      </Box>
    </Box>
  )
}

export default RawFileViewer