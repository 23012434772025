import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { AsyncSelect, ISelectAsync, TSelectOption } from 'rhf-modular-wrapper'
import { POST } from '../../../components/utils/fetch'
import {useFormContext} from "react-hook-form"
import { TOlgaFiles } from '../../../interfaces/files'
import FileUploaderV1, { TFileUploaderReturnObject } from '../../FileUploader/v1'

interface ISelectFileOrUploadv1 {
  type : TOlgaFiles,
  name : string, 
  label ?: string,
  selectProps ?: Partial<ISelectAsync>
}

const SelectFileOrUploadv1 = (props : ISelectFileOrUploadv1) => {
  const [createModal, setCreateModal] = useState<string|null>(null)
  const {setValue} = useFormContext()
  
  const createFileUpload = (fileName: string) => new Promise<TSelectOption>(async (resolve, reject) => {
    setCreateModal(fileName)
    resolve({label: 'Placeholder', value: 'Placeholder'}) 
  })

  const fileUploader = useCallback((a: TFileUploaderReturnObject) => new Promise<{status:boolean, data:any}>(async (resolve, reject) => {
    if (!Array.isArray(a)) {
      const formData = new FormData();

      formData.append('file_name',a['file_name'])
      formData.append('file_upload[]',a['file_upload'][0])
      formData.append('file_type[]',props.type)
      formData.append('ret','select')

      POST('file/upload.php', formData,false)
      .then((response) => {
        resolve(response)
        setValue(props.name, response.data)
        setCreateModal(null)
      })
    }
  }),[props.type]) //eslint-disable-line

  const loadFiles = useCallback((key:string, callback: (a:TSelectOption[])=> void) => {
    POST('file/index.php',{action: 'GET_FILE_NAMES', extension: props.type, key: key})
    .then((response) => callback(response.data))
  },[props.type])


  return (
  <div>    
    <AsyncSelect label={props.label} defaultOptions name={props.name} isCreatable={createFileUpload} loadOptions={loadFiles} {...props.selectProps} />
    <Dialog fullWidth maxWidth="lg" open={createModal !== null} onClose={()=>setCreateModal(null)}>
      <DialogTitle>FILE UPLOADER [.{props.type} file]</DialogTitle>
      <DialogContent>
        <FileUploaderV1 fileType={props.type}  onSubmit={fileUploader} defaultValues={{file_name : createModal === null ? '' : createModal}} />
      </DialogContent>
    </Dialog>
  </div>
  )
}

export default SelectFileOrUploadv1