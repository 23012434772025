import React from 'react'
import FormWrapper from '../components/templates/FormWrapper'
import { IStandardForm } from '../components/interface'
import { FormHeader, FormSection, InputSection } from '../components/templates/FormTemplates'
import { Line } from 'rhf-modular-wrapper'

interface IFAAddDOR extends IStandardForm {}

const FAAddDOR = (props: IFAAddDOR) => {
  return (
    <FormWrapper
      submitCaption="Create Surge Points!"
      onSubmit={props.onSubmit}>
        <FormHeader>Get New Surge Calculation Points</FormHeader>
        <FormSection>
        <InputSection>
            <Line type="number" name="dor_start" label="Minimum DOR (m3)" helperText="Starting DOR Value" />
          </InputSection>
        <InputSection>
            <Line type="number" name="dor_end" label="Maximum DOR (m3)" helperText="Ending DOR Value" />
          </InputSection>
        <InputSection>
            <Line type="number" name="dor_steps" label="Steps" helperText="How many points to count between Minimum and Maximum (Including Min / Max points)" />
          </InputSection>
        </FormSection>
      </FormWrapper>
  )
}

export default FAAddDOR