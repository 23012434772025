import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PrivateRoute from "./Pages/Routings/PrivateRoute";
import LoginPage from "./components/Templates/Login";
import { TuseAuth, useAuth } from "./components/hooks/useAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RootTemplate from "./Pages/template/RootTemplate";
import FANew from "./Pages/FA/Estimator/New";
import FALearningNew from "./Pages/FA/Learning/New";
import FileAdd from "./Pages/Files/New";
import ViewFiles from "./Pages/Files/View";
import SubRootTemplate from "./Pages/template/SubRootTemplate";
import CreateTrainingBatch from "./Pages/FA/Learning/Train/New";
import ViewLearning from "./Pages/FA/Learning/View";
import Page404 from "./Pages/404";
import UserProfile from "./Pages/User/Profile";
import FaLearningNewBatch from "./Pages/FA/Learning/Batch";
import ViewEstimations from "./Pages/FA/Estimator/View";
import ViewMachineLearning from "./Pages/FA/MachineLearning/View";
import CreateUser from "./Pages/User/Create";
import TestPage from "./Pages/Test";
import UserList from "./Pages/User/List";
import CreateJob from "./Pages/Job/Create";
import ViewJobs from "./Pages/Job/View";
import DashboardPage from "./Pages/Dashboard";

export const UserContext = React.createContext<TuseAuth>({
  user: null,
  login: () => new Promise((res) => {}),
  logout: () => {},
  modify: (a: any, b ?:boolean) => new Promise((res) => {}),
  register: (a:any)=> new Promise((res) => {})
});

const App = () => {
  const { user, login, logout, modify, register } = useAuth("user/auth.php", "sfa");

  return (
    <UserContext.Provider value={{ user, login, logout, modify, register }}>
      <Router>
        <Routes>          
          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<DashboardPage />} />
            <Route path="user" element={<RootTemplate header="User" />}>
              <Route path="logout" element={<Logout logout={logout} />} />
              <Route path="create" element={<CreateUser />} />
              <Route path="profile" element={<UserProfile />} />
              <Route path="list" element={<UserList />} />
            </Route>
            <Route path="test" element={<TestPage />} />
            <Route
              path="fa"
              element={
                <RootTemplate
                  header="FA"
                  subHeader="Flow Assurance ML Estimator"
                />
              }
            >
              <Route
                path="estimation"
                element={
                  <SubRootTemplate
                    header=""
                    subHeader="Estimation"
                    root="/fa/estimation"
                    options={[
                      { name: "New Estimation", url: "new" },
                      { name: "Past Estimations", url: "history" },
                    ]}
                  />
                }
              >
                <Route path="new" element={<FANew />} />
                <Route path="history" element={<ViewEstimations />} />
              </Route>

              <Route
                path="learning"
                element={
                  <SubRootTemplate
                    header="Data for ML"
                    subHeader="Data to Teach the FA bot"
                    root="/fa/learning"
                    options={[
                      { name: "Single Data Entry", url: "new/single" },
                      { name: "Batch Data Entry", url: "new/batch" },
                      { name: "View Entries", url: "history" },
                    ]}
                  />
                }
              >
                <Route path="new">
                  <Route path="single" element={<FALearningNew />} />
                  <Route path="batch" element={<FaLearningNewBatch />} />
                </Route>
                <Route path="history" element={<ViewLearning />} />

                <Route path="train">
                  <Route path="new" element={<CreateTrainingBatch />} />
                  <Route path="view" element={<ViewMachineLearning />} />
                  <Route path="view/:type" element={<ViewMachineLearning />} />
                </Route>
              </Route>
            </Route>
            <Route path="files" element={<RootTemplate header="Files" />}>
              <Route path="new" element={<FileAdd />} />
              <Route path="view" element={<ViewFiles />} />
              <Route path="view/:type" element={<ViewFiles />} />
            </Route>
            <Route path="job" element={<RootTemplate header='Jobs' />}>
              <Route path="add" element={<CreateJob />} />
              <Route path="view" element={<ViewJobs />} />
            </Route> 
            <Route path="login" element={<LoginPage setUser={login} />} />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </UserContext.Provider>
  );
};

interface ILogout {
  logout: () => void;
}

const Logout = (props: ILogout) => {
  props.logout();
  return <p>Logging Out....</p>;
};
export default App;
