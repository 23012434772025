import { TUser } from '../../interfaces/user';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { POST, uploadFile } from '../utils/fetch';

type TLoginData = TUser

type TModifyData = {
  picture : File,
  name: string,
  email : string
}

export type TuseAuth = {
  user : TUser|null,
  login : (a:any) => Promise<any>,
  logout : () => void,
  modify : (a:TModifyData, ownModify ?: boolean) => Promise<any>,
  register : (a:any) => Promise<any>,
}

export const useAuth = (endpoint: string, prefix:string): TuseAuth => {  
  const [user, setUser] = useState<null|TLoginData>(JSON.parse(localStorage.getItem(`${prefix}-user`) ?? JSON.stringify(null)))

  const login = (a:any) => new Promise((resolve,reject) => {
    POST<TLoginData>(endpoint,{...a, action : 'USER_LOGIN'})
      .then(res => { 
        if(res.status) {
          toast.success(`Login Successful! Welcome back ${res.data.u_name}`)
          localStorage.setItem(`${prefix}-user`,JSON.stringify(res.data))
          setUser(res.data); 
          resolve(true)
        } else {
          toast.error("Login failed! Please check your email and/or password and try again!")
          localStorage.setItem(`${prefix}-user`,JSON.stringify(null))
          resolve(false); 
          reject(false)
        }  
      })
  })

  const register = (a:any) => new Promise<any>(async(resolve, reject) => {
    let picture = null
    if ( a.picture && a.picture.name) {
      picture = await uploadFile(a.picture)
    }

    POST<any>(endpoint,{...a, picture : picture, action : 'USER_REGISTRATION'})
      .then(res => { 
        if(res.status) {
          toast.success(`Registration Successful! You may log in with the email ${a.email} to continue`)
          resolve(true)
        } else {
          toast.error("Registration failed!")
          resolve(false); 
        }  
      })
  })

  const logout = () => {
    localStorage.removeItem(`${prefix}-user`)
    toast.success(`You have been logged out! Good bye!`)
    setUser(null)
  }

  const modify = (a:TModifyData, ownModify = true) => new Promise(async (resolve,reject) => {
    let picture: null | string | false = null
    if ( a.picture && a.picture.name) {
      picture = await uploadFile(a.picture)
    }

    POST(endpoint,{...a, picture: picture, action : 'USER_MODIFY'})
    .then(res => { 
      if(res.status && user !== null && (ownModify || a.email === user.u_email))  {
        setUser({
          ...user, 
          u_name: a.name, 
          u_email: a.email,
          u_photo: "/file/profiles/" + picture ?? ''
        }); 
        
        localStorage.setItem(`${prefix}-user`,JSON.stringify({
          ...user, 
          u_name: a.name, 
          u_email: a.email,
          u_photo: "/file/profiles/" + picture ?? ''
        }))
        resolve({status:true,data:'MODIFY_OK'})
      } else {
        resolve(res.data); reject(false) 
      }
    })

  })

  return {user, login, logout, modify, register}
}