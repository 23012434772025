import React, { useState } from 'react'
import LoadingOverlay from 'react-loading-overlay-ts';
import { TStandardPromise } from '../../../Forms/components/interface';
import FormWrapper from '../../../Forms/components/templates/FormWrapper';
import { Line, WYSIWYGEditor } from 'rhf-modular-wrapper';
import { Box } from '@mui/material';
import { FormSection } from '../../../Forms/components/templates/FormTemplates';
import { POST } from '../../../components/utils/fetch';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreateJob = () => {
  const navigate = useNavigate()
  const [overlay, setOverlay] = useState<boolean>(false)

  const onSubmit = (a:any) => new Promise<TStandardPromise>(resolve => {
    setOverlay(true)
    toast.loading('Creating Job...',{isLoading:true, autoClose:false, toastId:'add_job'})
    POST("job/index.php",{action: "ADD_JOB", ...a})
    .then(response => {
      if(response.status) {
        toast.update('add_job',{render:'Job Created!',toastId:'add_job', autoClose:2000, isLoading:false, type:'success'})
      } else {
        toast.update('add_job',{render:'Job Not Created!',toastId:'add_job', autoClose:2000, isLoading:false, type:'error'})
      }
      setOverlay(false)
      navigate('/job/view')

    }).catch((err) => {
      toast.update('add_job',{render:'Job Not Created!',toastId:'add_job', autoClose:2000, isLoading:false, type:'error'})
      setOverlay(false)
    }) 
  })
  return (
    
    <LoadingOverlay active={overlay} spinner text="Parsing and Generating Surge Volume ....">
      <Box height="100%" width="100%">
        <FormWrapper onSubmit={onSubmit} submitCaption='Create Job'>
          <FormSection>
            <Line name="name" label="Job Name / ID" />
          </FormSection>
          <FormSection sx={{marginBottom:'150px', width:'100%'}}>            
            <WYSIWYGEditor name="description" label="Job Description" />
          </FormSection>
        </FormWrapper>
      </Box>
    </LoadingOverlay>
  )
}

export default CreateJob