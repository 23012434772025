import React, { useContext, useState } from 'react'
import FALearningV2, { TFALearningReturnObject } from '../../../../Forms/FaLearning/v2'
import { POST } from '../../../../components/utils/fetch'
import { UserContext } from '../../../../App'
import { toast } from 'react-toastify'
import LoadingOverlay from 'react-loading-overlay-ts';
import LearningViewer from '../../component/LearningViewer'
import { convertPressure } from '../../../../Forms/components/Converter/Pressure'

//eslint-disable-next-line 
type TLearningReturnV1 = {
  data : {
    glr: {value: number, unit: string},
    wc: {value: number, unit: string}
  },
  job : string,
  learningID : string,
  pipes : {    
    avg_diameter: number,
    avg_roughness: number,
    "fullLength(x)" : number,
    "fullLength(y)" : number,
    ids : string[]
  },
  result : {  
    deltas : number[],
    surge_volume : number
  }
}



const FALearningNew = () => {
  const [overlay, setOverlay] = useState<boolean>(false)
 const {user} = useContext(UserContext)

  const onSubmit = (a:TFALearningReturnObject)=> new Promise<{status:boolean, data:any}>((resolve, reject) => {
    setOverlay(true)
    const toastID = toast.loading("Adding Data... Please Give Me a Moment....", {isLoading: true,autoClose : false})
    a.data.p_in = Number(convertPressure(a.data.p_in, a.data['p_in-unit'].value,'barg'))
    a.data.p_out = Number(convertPressure(a.data.p_out, a.data['p_out-unit'].value,'barg'))
    a.data.p_drop = Number(convertPressure(a.data.p_drop, a.data['p_drop-unit'].value,'barg'))
    POST<any>( "ml/learning.php", { data:a, user : user, action:"ADD_LEARNING" })
    .then(res => { 
      if (res.status) {
        toast.update(toastID, {
          type: 'success',
          render : (props) => <LearningViewer {...props} {...res} />,
          isLoading: false,
          autoClose: 3000
        })
      } else {
        toast.update(toastID, {
          type: 'error',
          render : `Error Creating new Learning Row! Error : ${typeof res.data === 'object' ? JSON.stringify(res.data) : res.data}`,
          isLoading: false,
          autoClose: 3000
        })
      }   
      setOverlay(false)
      resolve(res)
    }).catch(err => {
      // console.log("[err]- ",err)
      setOverlay(false)
      reject(false)
      toast.update(toastID, {
        type: 'error',
        render : <div><p className="text-danger">An Error Occured!</p></div>,
        isLoading: false,
        autoClose: 3000
      })
    })
  }) //eslint-disable-line

  return (
    <LoadingOverlay active={overlay} spinner text="Parsing and Generating Surge Volume ....">
      <FALearningV2 onSubmit={onSubmit}/>
    </LoadingOverlay>
  )
}

export default FALearningNew