import React from 'react'
import Box from '@mui/material/Box';
import { Form, Line, SubmitButton } from 'rhf-modular-wrapper';
import Hero from "./hero.png"
import SubmitTypeButton from '../Form/SubmitButton';

interface ILogin { setUser: (a:any) => Promise<any> }

const Login = (props:ILogin) => {
  const onSubmit = (a:any) => new Promise((res,rej) => res(props.setUser(a))) 

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        position:'relative',
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(230deg, #0038c4, #0077f91a)",
      }}
    >
      <Box sx={{
        p:4,
        width : '550px',
        height : '400px',
        background : 'white',
        borderRadius :'5px',
        boxShadow : '1px 3px 6px #4e4e4e'
      }}>
        <img src={Hero} alt="Synergy FA Estimator" height="200" width="auto" />
        <Form onSubmit={onSubmit} buttonWrapper={SubmitTypeButton}>
          <Line name="email" label="Email" type="email" />
          <Line name="password" label="Password" type="password" />
          <Box sx={{pt:2}}><SubmitButton>Login</SubmitButton></Box>
        </Form>
        <Box sx={{
          position:'absolute',
          bottom:10,
          width:'100%',
          textAlign:'center',
          right:'10px'
        }}>
          Developed by Augment Sdn. Bhd. for Synergy Oil and Gas Engineering Sdn. Bhd.
        </Box>
      </Box>
    </div>
  )
}

export default Login