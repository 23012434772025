import React, { useContext, useMemo } from 'react'
import { IStandardForm } from '../components/interface'
import FormWrapper from '../components/templates/FormWrapper'
import { Line } from 'rhf-modular-wrapper'
import Avatar from '../components/Avatar'
import { Box, Typography } from '@mui/material'
import * as yup from "yup"
import { POST } from '../../components/utils/fetch'
import { UserContext } from '../../App'

interface IUserProfileForm extends IStandardForm {}



const UserProfileForm = (props: IUserProfileForm) => {
  const {user} = useContext(UserContext)
  const schema = useMemo(()=>{
    return yup.object({
      name: yup.string().required('A Name is required!'),
      email: yup.string().required('An email is required!'),
      password: yup.string().nullable()
        .test('incorrect-pass','The Previous Password is incorrect!', async (value, ctx) => {
          if (value === '' || value === undefined || value === null) { return true}

          const res = await POST('user/auth.php',{action:'CHECK_PASS',pass: value, id: user?.u_id, session: user?.key})
          if (res.status) {
            return res.data
          }
          return false
      }),
      new_password: yup.string().nullable()
        .test('old-pass', 'Please also enter the previous password!', (value, ctx) => { 
          if (value === '' || value === null) {return true}
          else {
            if (ctx.parent.password === '' || ctx.parent.password === null) {return false}                      
          }
          return true
        }).test('pass-same','New Passwords must match!',(value, ctx) => {
          if (value === '' || value === null) {return true}
          else {
            if (ctx.parent.confirm_password !== value) {return false}
          }
          return true;
        }),
    })
  },[]) //eslint-disable-line 
  return (
    <FormWrapper schema={schema} onSubmit={props.onSubmit} defaultValues={props.defaultValues} submitCaption='Update User Profile'>
      <Box display={'flex'} flexDirection={'row'} width='100%'>
        <Avatar name="picture" label="Avatar Picture" />
        <Line name="name" label="Name" />        
      </Box>
      <Box mt={3} sx={{display:'flex', flexDirection:'row'}}>
        <Line name="email" label="Email" />
      </Box>
      <Typography mt={5} textAlign={'center'} fontSize={'2em'}> - To change Password - </Typography>
      <Box mt={3} sx={{display:'flex', flexDirection:'row'}}>
        <Line name="password" label="Old Password" />
      </Box>
      <Box mt={3} sx={{display:'flex', flexDirection:'row'}}>
        <Line name="new_password" label="New Password" />
        <Line name="confirm_password" label="Confirm Password" />
      </Box>
    </FormWrapper>
  )
}

export default UserProfileForm