import React, {useState, useEffect, useContext} from 'react'
import Button from "@mui/material/Button/Button"
import Dialog from '@mui/material/Dialog/Dialog'
import Typography from '@mui/material/Typography/Typography'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import FormWrapper from '../../../../../Forms/components/templates/FormWrapper'
import { TStandardPromise } from '../../../../../Forms/components/interface'
import UserProfileForm from '../../../../../Forms/UserProfile'
import { AsyncSelect, Line } from 'rhf-modular-wrapper'
import { UserContext } from '../../../../../App'
import { POST, base_url, getBlob } from '../../../../../components/utils/fetch'
import { toast } from 'react-toastify'
import { loadModels } from '../../../../../components/utils/loadOptions'

const ActionModal = (props: any) => {
  const [defaultValues , setDefaultValues] = useState<any>(null)
  const {user, modify} = useContext(UserContext)
  const title = 
    props.type === 'change_pass' ? 'Change Password':
    props.type === 'update'? 'Update User':
    props.type === 'delete' ? 'Delete User': ''
  
  useEffect(()=>{
    if (props.type === 'change_permission') {
      POST('ml/estimation.php',{action:'GET_USERS_MODELS', u_id : props.user.u_id})
      .then(response => {
        if (response.status) {
          setDefaultValues(response.data)
        }
      })
    } else if (props.type === 'update') {
      // Get image
      if (props.user?.u_photo) {
        getBlob(base_url + props.user.u_photo)
        .then(file => {
          setDefaultValues({
            picture: file, 
            email_old: props.user?.u_email, 
            name: props.user?.u_name, 
            email: props.user?.u_email
          })
        })
      } else {
        setDefaultValues({
          email_old: props.user?.u_email, 
          name: props.user?.u_name, 
          email: props.user?.u_email
        })
      }
    }

    return () => setDefaultValues(null)
  },[props.type, props.user?.u_id]) //eslint-disable-line
  
  const submit = (a:any) => new Promise<TStandardPromise>((resolve, reject) => {
    const id = toast.loading('Sending Action...',{isLoading: true, autoClose:false})
    switch(props.type) {
      case 'change_pass':
        POST('user/auth.php',{
          action:"UPDATE_PASSWORD", 
          id:user?.u_id, 
          session: user?.key,
          user: props.user.user_id,
          new_password: a.new_password
        }).then(res => {
          if (res.data) {
            toast.update(id, {type:'success',render:`Password Updated for ${props.user.u_name}!`,isLoading:false, autoClose:2000})
          } else {
            toast.update(id, {type:'error',render:`Something went wrong!`,isLoading:false, autoClose:2000})
          }
        })
        break;      
      case 'update':
        modify(a, false)
        .then(res => {
          if (res.data === 'MODIFY_OK') {
            toast.update(id, {type:'success',render:`Profile Updated for ${props.user.u_name}!`,isLoading:false, autoClose:2000})
          } else {
            toast.update(id, {type:'error',render:`Something went wrong!`,isLoading:false, autoClose:2000})
          }
        })
        break;
      case 'delete':
        POST('user/auth.php',{
          action:"DELETE_USER", 
          id:user?.u_id, 
          session: user?.key,
          user_id: props.user.u_id,
        }).then(res => {
          if (res.data) {
            toast.update(id, {type:'success',render:`${props.user.u_name} Deleted!`,isLoading:false, autoClose:2000})
          } else {
            toast.update(id, {type:'error',render:`Something went wrong!`,isLoading:false, autoClose:2000})
          }
        })
        break;
      case 'change_permission':
        POST('user/auth.php',{
          action:"UPDATE_PERMISSIONS", 
          id:user?.u_id, 
          session: user?.key,
          user_id: props.user.u_id,
          allowed_ml : a.allowed_ml
        }).then(res => {
          if (res.data) {
            toast.update(id, {type:'success',render:`${props.user.u_name} Deleted!`,isLoading:false, autoClose:2000})
          } else {
            toast.update(id, {type:'error',render:`Something went wrong!`,isLoading:false, autoClose:2000})
          }
        })
        break;
    }

    resolve({status:true,data:null})
    props.close()
  })

  return (
    <Dialog open={props.type !== null} onClose={()=>props.close()} maxWidth='xl' fullWidth>
      <DialogTitle>User Action - {title}</DialogTitle>
      <DialogContent sx={{minHeight:'500px'}}>
        {
          props.type === 'change_pass' ? <FormWrapper onSubmit={submit} submitCaption='Reset Password'>
            <Typography>Change User Password ?</Typography>
            <Line name='new_password' />
          </FormWrapper> : 
          props.type === 'update' && defaultValues !== null ? <UserProfileForm defaultValues={defaultValues} onSubmit={submit}/> :
          props.type === 'delete' ? <FormWrapper onSubmit={submit} submitCaption='Delete User'>
            <Typography fontSize='2em' textAlign={'center'}>Are you sure? This action cannot be reversed!</Typography>
            <Button type='button' onClick={()=>props.close()} variant='contained' color='warning'>Dont Delete</Button>
          </FormWrapper> : 
          props.type === 'change_permission' && defaultValues !== null ? <FormWrapper defaultValues={defaultValues} onSubmit={submit} submitCaption='Change Permissions'>
            <AsyncSelect 
              defaultOptions
              name='allowed_ml'
              loadOptions={(a,b) => loadModels(a,b)}
              label='Allowed Models'
              rsOptions={{isMulti:true}}
            />
          </FormWrapper>
         : null
        }
      </DialogContent>
    </Dialog>
  )
}

export default ActionModal