import { Box, Button,  Stack,  Typography, LinearProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { TOlgaFiles } from '../../../interfaces/files'
import { AugTable } from '../../../components/Table'
import FileModal from './component/FileModal'
import { useNavigate, useParams } from 'react-router-dom'
import FileCacheTableView from './component/FileCacheTableView'
import { Grid } from '@syncfusion/ej2-react-grids'
import { POST } from '../../../components/utils/fetch'
import { toast } from 'react-toastify'
import FileActions from './component/FileActions'
import parseHandlerFunction from '../../../components/ParseHandler/parseHandlerFunction'

export type TFileTable = {
  fi_name : string,
  fi_size : string,
  fi_srcpath : string
}

const ViewFiles = () => {
  const tableRef = useRef<Grid>(null)
  const navigate = useNavigate()
  const {type} = useParams()
  
  const [fileType, setFileType] = useState<TOlgaFiles>(type as TOlgaFiles ?? 'genkey')
  const [activeFile, setActiveFile] = useState<TFileTable|null>(null)

  useEffect(()=>{
    if (type === 'undefined') {navigate('/files/view/genkey')}
    else if (type !== fileType) {navigate(`/files/view/${type}`)}
  },[type]) // eslint-disable-line

  const handleClick = (clickType:TOlgaFiles) => {
    setFileType(clickType)
    navigate(`/files/view/${clickType}`)    
  }

  const resizeTable = () => {
    if (tableRef.current !== undefined && tableRef.current !== null) {
      tableRef.current.autoFitColumns([])
    }
  }

  const regenerateAllCache = async () => {
    const {data:files}:{data:{fi_name:string}[]} = await POST('file/index.php',{action:'GET_FILE_LIST_NO_CACHE'})
    let empty_files = files.map(x => ({...x, status:'Not Complete'}))

    for (let i =0; i < files.length; i++) {
      const file = files[i];
      const toastID = toast.loading(`Recaching file ${file.fi_name}... (${i + 1} / ${files.length})`, {isLoading: true})
      try {
        let ret = await POST('file/index.php',{action:'REGENERATE_CACHE', fileName: file.fi_name})
        while (ret.data.status === false) {
          ret = await POST('file/index.php',{action:'REGENERATE_CACHE', fileName: file.fi_name})
          if (ret.data.status === false) {
            toast.update(toastID,{
              type: 'info',
              render : `Currently Line ${ret.data?.data?.current_line ?? 0} / ${ret.data?.data?.total_lines ?? 0}!`,
              isLoading: true,
              autoClose : false
            })
          }
        }
        toast.update(toastID,{
          type: 'success',
          render : `Files [${i}] ${file.fi_name} Re-cached!`,
          isLoading: false,
          autoClose : 2000
        })
        empty_files[i].status = 'Complete'
      } catch (err) {
        toast.update(toastID,{
          type: 'error',
          render : `Failed to cache file : ${file.fi_name}`,
          isLoading: false,
          autoClose : 2000
        })
        empty_files[i].status = 'Error'
      }
    }
  }

  const resetAndRegenCache = (useType:null|string = null) => new Promise(async(resolve) => {
    toast.loading(<Box>
      <Typography variant='h4'>Resetting Cache</Typography>
      {useType && <Typography variant='h6'>{useType.toUpperCase()} Files</Typography>}
      <Typography variant='body1' fontWeight={'bold'}>Getting File List....</Typography>
    </Box>,{toastId: 'resetCache', autoClose:false})

    const {data:files}:{data:{fi_name:string}[]} = await POST('file/index.php',{action:'GET_FILE_LIST', type: useType})
    

    for (let i = 0; i < files.length; i++) {    
      toast.update('resetCache',{render: <Box>
        <Typography variant='h4'>Resetting Cache</Typography>
        {useType && <Typography variant='h6'>{useType.toUpperCase()} Files</Typography>}
        <Typography variant='body1' fontWeight={'bold'}>Resetting And Parsing Files...</Typography>
        <Typography variant='caption' fontWeight={'bold'}>Current File : {files[i].fi_name}</Typography>
        <Box mt={2}>
          <Typography variant='caption'>{i} / {files.length} completed</Typography>
          <LinearProgress variant="determinate" 
            value={100 * (i) / files.length} />
        </Box>
      </Box>})
      await parseHandlerFunction(files[i].fi_name)
    }

    toast.update('resetCache',{
      render: <Box>
        <Typography variant='h4'>Resetting Cache</Typography>        
        {useType && <Typography variant='h6'>{useType.toUpperCase()} Files</Typography>}
        <Typography variant='body1' fontWeight={'bold'}>Resetting Complete!</Typography>
      </Box>,
      isLoading: false,
      autoClose:2000,
      type:'success'
    })
     
  })

  const cleanUpFiles = async () => {
    const {data:files}:{data:{fi_name:string}[]} = await POST('file/index.php',{action:'GET_FILE_LIST'})
    const promises:Promise<any>[] = [];
    const toastID = toast.loading('Cleaning and caching files...',{type:'info',autoClose : false, isLoading: true})
    files.forEach(file => {
      promises.push(POST('file/index.php',{action:'CLEANUP_FILE', fileName: file.fi_name}))
    })

    Promise.all(promises).then((res) => {
      toast.update(toastID,{
        type: 'success',
        render : 'Files Cleanup Complete!',
        autoClose: 2000,
        isLoading: false
      })
      tableRef.current?.refresh();
    }).catch(err => {
      toast.update(toastID,{
        type: 'error',
        render : `Error Cleaning File! Err : ${err.location ?? err.message ?? typeof err === 'object' ? JSON.stringify(err) : err}`,
        autoClose: 2000,
        isLoading: false
      })
    }) 
  }

  return (
    <Stack spacing={2}>    
      <Stack sx={{my:5}} direction='column' spacing={2}>
        <Box>
          <Button color='error' onClick={()=>resetAndRegenCache()} variant='contained'>
            <Box sx={{textAlign:'left'}}>
              <Typography>Reset and Regenerate All Cache</Typography>
              <Typography variant='caption'>May take awhile if alot of files...</Typography>
            </Box>
          </Button>
        </Box>
        <Box>
          <Button onClick={()=>resetAndRegenCache(type)} variant='contained'>
            <Box sx={{textAlign:'left'}}>
              <Typography>Reset and Regenerate For Current Type</Typography>
              <Typography variant='caption'>Regenerate for {type}</Typography>
            </Box>
          </Button>
        </Box>
      </Stack>  
      <Box 
          sx={{
            mt: 3,        
            mb :4,
            display: 'flex', 
            justifyContent: 'center'
          }}
        >
        <Button sx={{mx:3}} variant={type === 'genkey' ? 'contained' : 'outlined' } onClick={()=>handleClick('genkey')}>genkey</Button>
        <Button sx={{mx:3}} variant={type === 'tab' ? 'contained' : 'outlined' } onClick={()=>handleClick('tab')}>tab</Button>
        <Button sx={{mx:3}} variant={type === 'tpl' ? 'contained' : 'outlined' } onClick={()=>handleClick('tpl')}>tpl</Button>
{/*         <Button sx={{mx:3}} variant={fileType === 'ppl' ? 'contained' : 'outlined' } onClick={()=>handleClick('ppl')}>ppl</Button>
 */}      </Box>

      <Box>
        <AugTable 
          dataBound={resizeTable}
          ref={tableRef}
          setCurrentActiveRow={setActiveFile}
          allowResizing
          dataManagerUrl={{url:`file/index.php`}}
          query={[
            {param:'action', value:'GET_TABLE'},
            {param:'fileType', value: fileType}
          ]}
          columns={[
            {field: 'fi_name', headerText:'File Name'},
            {field: 'fi_size', headerText:'File Size'},
            {field: 'fi_params', headerText:'Cached Parameters', template: (a:any) => <FileCacheTableView {...a} tableRef={tableRef} />},
            {field: 'actions', headerText:'Actions', template: (a:any) => <FileActions {...a} tableRef={tableRef} />}
          ]}
        />
      </Box>
      <Box sx={{mt:5}}>
        <Typography variant="h4">Special Actions</Typography>
        <Box sx={{display:'flex',flexDirection:'row', justifyContent:'center'}}>
          <Button sx={{mr:3}} variant='contained' onClick={() => regenerateAllCache()} >Generate Unwritted Cache </Button>
          <Button variant='contained' onClick={() => cleanUpFiles()} >Clean up Files</Button>
        </Box>
      </Box>
      <FileModal activeFile={activeFile} onHide={()=>setActiveFile(null)} fileType={fileType} />
    </Stack>
  )
}

export default ViewFiles