import React, {useContext} from 'react'
import { TStandardPromise } from '../../../Forms/components/interface'
import CreateUserForm from '../../../Forms/CreateUser'
import { UserContext } from '../../../App'
import { toast } from 'react-toastify'

const CreateUser = () => {
  const {user, register} = useContext(UserContext)
  const onSubmit = (a:any) => new Promise<TStandardPromise>((resolve, reject) => {
    if (user?.u_role !== 'ADMIN') {
      toast.error("You don't have permission to create users!")
      resolve({status:false, data:null})
    } else {
      register(a).then(()=>{
        resolve({status:true, data:null})
        toast.success('User created successfully!')
      })

    }
  })

  return (
    <CreateUserForm onSubmit={onSubmit} />
  )
}

export default CreateUser