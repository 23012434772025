import { ActionCompleteEventArgs, UploaderComponent } from '@syncfusion/ej2-react-inputs'
import React from 'react'
import { /* POST */ base_url } from '../../../components/utils/fetch'
import { /* Id, */ toast } from 'react-toastify'
import parseHandlerFunction from '../../../components/ParseHandler/parseHandlerFunction'

interface IUploader  {
  label?: string,
  helperText ?: string,
  name: string,
  multiple ?: boolean,
  allowedExtensions?: string
}

// const parseFileHandler = (fileName: string, toastID: Id) => new Promise<boolean>(async (resolve, reject) => { 
//   let result = null
//   try {
//     result = await POST('file/index.php',{action:'REGENERATE_CACHE', fileName: fileName})  
//   } catch (err) {
//     // console.log('Error First Regen : ',err)
//     resolve(false)
//   }

//   // console.log("Result ", result)

//   let rev = 2;
//   while (result !== null && result.data?.status === false) {  
//     if (result.data.status === false) {
//       toast.update(toastID, {
//         render: `Currently Line ${result.data?.data?.current_line ?? 0} / ${result.data?.data?.total_lines ?? 0}!`,
//         type: 'info',
//         isLoading: true,
//         autoClose: false
//       })
//     } 
//     // console.log('Current Rev : ',rev) 
//     try {
//       result = await POST('file/index.php',{action:'REGENERATE_CACHE', fileName: fileName})
      
//     } catch (err) {
//       // console.log(`Error ${rev} Regen : `,err)
//       resolve(false)
//     }
//     rev++
//   }

//   toast.update(toastID,{
//     render : `File ${fileName} parsed successfully!`,
//     type: 'success',
//     autoClose: 5000,
//     isLoading: false
//   })
//   resolve(true)
// })

const Uploader = (props:  IUploader) => {
  

  const settings = {
    chunkSize : 1_024_000,
    saveUrl : base_url + '/file/upload2.php',
    removeUrl : base_url + '/file/upload2.php?remove=true',
  }
  const actionComplete = async (a:ActionCompleteEventArgs) => {
    // a.e.preventDefault()
    // console.log("[actionComplete] - ",a)
    const promises:Promise<boolean>[] = []
    // File uploaded
    a.fileData.forEach(file => {
      if (file.status === 'File uploaded successfully') {
        // promises.push(parseFileHandler(file.name,toastID))
        promises.push(parseHandlerFunction(file.name))
        // const toastID = toast.loading(`Proceeding to parse file ${file.name}`, {isLoading: true, autoClose: false})
      }
    })

    let allPromises = null
    try {
      allPromises = await Promise.all(promises)
    } catch (err: any) {
      // console.log(`Error Parsing file : `,err)

      toast.error(err?.message ?? err)
      return false;
    }

    allPromises.forEach((promise,i) => {
      if (!promise) {
        toast.error(`File ${a.fileData[i]} could not be parsed! Please try again!`)
      }
    })

    toast.success(`${allPromises.filter(x => x === true).length} Files uploaded successfully!`)

  }
  const success = (a:any) => {
    a.e.preventDefault()
    // console.log("[success] - ",a)
  } 
  const failure = (a:any) => {
    // a.e.preventDefault()
    // console.log("[failure] - ",a)
  }
  
  return <UploaderComponent failure={failure} actionComplete={actionComplete} success={success} autoUpload={false} {...props} maxFileSize={10024000000} multiple={props.multiple} asyncSettings={settings}/>
}

export default Uploader