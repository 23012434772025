import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { POST } from "../../../components/utils/fetch";
import { IStandardForm, TFAMode } from "../../components/interface";
import * as Yup from "yup";
import { AsyncSelect, TSelectOption, Line, Radiobox, Select } from "rhf-modular-wrapper";
import { FormHeader, FormSection, InputSection } from "../../components/templates/FormTemplates";
import FormWrapper from "../../components/templates/FormWrapper";
import { useFormContext } from "react-hook-form";
import { UserContext } from "../../../App";
import PressureConverterInput from "../../components/Converter/Pressure";

export type TFAEstimateV1Return = {
  tag?: string;
  job: TSelectOption;
  mode: TFAMode;
  glr: number;
  flow_rate: number;
  pipeline: {
    length: number;
    roughness: number;
    intDia: number;
  }[];
};

interface IFAEstimateV1 extends IStandardForm<TFAEstimateV1Return> {}

const FAEstimateV1Schema = Yup.object({
  job: Yup.mixed().test(
    "is-option",
    "PLEASE CHOOSE OR CREATE JOB NAME",
    (val) => val?.value !== undefined
  ),
  mode: Yup.string().nullable().is(["SlugTracking", "Pigging", "SteadyState"]),
  glr: Yup.number().nullable().min(0, "NUMBER MUST BE GREATER THAN 0"),
  flow_rate: Yup.number().nullable().min(0, "NUMBER MUST BE MORE THAN 0"),
  pipeline: Yup.array().of(
    Yup.object({
      length: Yup.number().nullable().min(0, "NUMBER MUST BE GREATER THAN 0"),
    })
  ),
});

const FAEstimateV1 = (props: IFAEstimateV1) => {
  const loadJobs = useCallback(
    (key: string, callback: (a: TSelectOption[]) => void) =>
      new Promise((resolve, reject) => {
        POST<TSelectOption[]>("job/index.php", {
          action: "GET_JOB_SELECT",
          key: key ?? "",
        }).then((response) => {
          // console.log("[loadJobs] - ", response);
          callback(response.data);
        });
      }),
    []
  );

  return (
    <FormWrapper
      submitCaption="Get Estimate!"
      onSubmit={props.onSubmit}
      defaultValues={{mode:'SteadyState', pipeline : [{roughness:0}], ...props.defaultValues}}
      schema={FAEstimateV1Schema}
    >
      {/* Get Job */}
      <FormHeader>General Properties</FormHeader>
      <FormSection fd="column">
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "row" }}>
          <InputSection>
            <Line
              name="tag"
              label="Estimate Name (Tag)"
              helperText="A Name or Tag for this dataset. Not required"
            />
          </InputSection>
          <InputSection>
            <AsyncSelect
              defaultOptions
              label="Select or Create Job"
              helperText="The Job that this Estimate is linked to"
              name="job"
              loadOptions={loadJobs}
              isCreatable
            />
          </InputSection>
        </Box>
        <ChoosingType />
      </FormSection>

      {/* Details */}

      <FormHeader>Liquid Properties</FormHeader>
      <FormSection>
        <InputSection>
          <Line
            type="number"
            name="glr"
            label="Gas to Liquid Ratio (GLR)"
            helperText="What is the GLR (in sm3/sm3)"
          />
        </InputSection>
        <InputSection>
          <Line
            type="number"
            name="flow_rate"
            label="Flow Rate (kg/h)"
            helperText="Please Input the Flow Rate in kg/h"
          />
        </InputSection>
        <InputSection>
          <Line
            type="number"
            name="dor"
            label="Draw off Rate (m3/h)"
            helperText="Draw off Rete. For infinite leave at 0"
          />
        </InputSection>
      </FormSection>

      {/* Pipeline Properties */}
      <FormHeader>Pipeline Properties</FormHeader>
      <FormSection>
        <InputSection>
          <Line
            type="number"
            name="pipeline.0.length"
            label="Length of the pipeline (x axis)"
            helperText="Length of the Pipeline in m"
          />
        </InputSection>
          <Line
            type="hidden"
            name="pipeline.0.roughness"
            label="Roughness of pipeline (mm)"
            helperText="Roughness of the Pipeline in mm"
          />
        <InputSection>
          <PressureConverterInput name='p_drop' label="Pressure Drop" />
        </InputSection>
        {/* <InputSection>
        </InputSection> */}
        <InputSection>
          <Line
            type="number"
            name="pipeline.0.intDia"
            label="Internal Diameter of pipeline (mm)"
            helperText="Internal Diameter of the Pipeline in mm"
          />
        </InputSection>
      </FormSection>
    </FormWrapper>
  );
};

const ChoosingType = () => {
  const {user} = React.useContext(UserContext)
  const [type, setType] = useState<any>("")
  const [models, setModels] = useState<TSelectOption[]>([])

  const {setValue} = useFormContext()

  useEffect(()=>{
    POST("ml/estimation.php",{action: "GET_MODELS", type: type, id: user?.u_id })
    .then(res => {
      if (res.status) {
        setValue('model', res.data[0] ?? null)
        setModels(res.data)
      }
    })
  },[type]) //eslint-disable-line

  return <Box display='flex' flexDirection={'column'}>    
    <InputSection>
      <Radiobox
        externalStateSetter={setType}
        orientation="horizontal"
        name="mode"
        label="Mode"
        helperText="Select The Type of Estimation"
        options={[
          { value: "SlugTracking", label: "Slug Tracking" },
          { value: "Pigging", label: "Pigging" },
          { value: "SteadyState", label: "Steady State" },
        ]}
      />        
    </InputSection>
    <InputSection>
      <Select label="Select Model" helperText="Choose a ml model to use" name="model" options={models} />
    </InputSection>
  </Box>

}

export default FAEstimateV1;
