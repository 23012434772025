import React, { useContext, useEffect } from "react";
import RootPage from "../Root";
import Login from "../../components/Templates/Login";
import { UserContext } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";

// Assume you have a `isLoggedIn` state variable that is true if the user is logged in.

interface IPrivateRoute {
}

const PrivateRoute = (props: IPrivateRoute) => {
  const {user, login} = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(()=>{ if (user === null && (location.pathname === "/user/logout")) { navigate('/',{replace:true})  } },[location.pathname, user]) //eslint-disable-line

  return user === null ? <Login setUser={login} /> : <RootPage />;
};

export default PrivateRoute;
