import React, { useContext, useState } from "react";
import FAEstimateV1, {
  TFAEstimateV1Return,
} from "../../../../Forms/FAEstimate/v1";
import { POST } from "../../../../components/utils/fetch";
import { UserContext } from "../../../../App";
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay-ts';
import EstimationViewer from "../../component/EstimationViewer";

const FANew = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const {user} = useContext(UserContext)
  const onSubmit = (a: TFAEstimateV1Return) =>
    new Promise<{ status: boolean; data: any }>((resolve, reject) => {
      setLoading(true)
      const toastID = toast.loading("Creating Estimation...", {isLoading: true, autoClose: false})
      POST(
        "ml/estimation.php",
        {
          data: a,
          user : user,
          action : "ADD_ESTIMATION"
        }
      ).then((response) => {
        setLoading(false)
        resolve(response)
        toast.update(toastID, {
          type: "success",
          render : (props) => <EstimationViewer {...props} {...response} />,
          isLoading: false,
          autoClose : 3000
        })
      }).catch(err => {
        setLoading(false)
        resolve(err) 
        toast.update(toastID, {
          type: "error",
          render : <div>
            <p>Error in getting Estimate!</p>
            <p>Error : {err?.location ?? err.message ?? typeof err === 'object' ? JSON.stringify(err) : err}</p>
          </div>,
          isLoading: false,
          autoClose : 3000
        })
      })
    });

  return <LoadingOverlay active={loading} text="Generating ML Estimation...">
    <FAEstimateV1 onSubmit={onSubmit} />
  </LoadingOverlay>;
};

export default FANew;
