import React, { useState, useEffect, useContext } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { UserContext } from '../../../../App';
import { useNavigate } from 'react-router-dom';
import { base_url } from '../../../../components/utils/fetch';

const settings = ['Profile', 'Logout'];
const _pages = ['New Estimate', 'All Estimates']
interface INavBar {
}

const Navbar = (props: INavBar) => {
  const [pages, setPages] = useState(_pages)
  const {user} = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(()=>{
    if (user && user.u_role === "ADMIN" && pages.length < 3) {
      setPages(prev => ([...prev, "ML"]))
    }

    return () => setPages(_pages)
  },[]) // eslint-disable-line 
  
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (a ?: any) => {
    setAnchorElNav(null);
    if (typeof a === 'string') {
      switch(a) {
        case 'New Estimate':
          navigate('/fa/estimation/new')
          break;
        case 'All Estimates':
          navigate('/fa/estimation/history')
          break;
        case 'ML':
          navigate('/fa/learning/train/new')
          break;
      }
    }
  };

  const handleCloseUserMenu = (a ?: any) => {
    setAnchorElUser(null);
    
    if (typeof a === 'string') {
      switch(a) {
        case "Profile":
          navigate("/user/profile")
          break;
        case 'Logout':
          navigate('/user/logout')
          break;   
      }
    }
  };

  return (
    <AppBar position="relative" sx={{height:'60px',zIndex:1}}>
    <Container maxWidth={false}>
      <Toolbar disableGutters>
        <Box component="img" src="/assets/img/logo/Synergy_icon.png" onClick={()=>navigate('/')} sx={{cursor:'pointer', height:40, width:'auto', display: { xs: 'none', md: 'flex' }, mr: 1 }} />
        
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={()=>handleCloseNavMenu(page)}>
                <Typography textAlign="center">{page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 1, height:40, width:'auto', display: { xs: 'flex', md: 'none' }, mr: 1 }} >
          <Box component="img" src="/assets/img/logo/Synergy_icon.png" />
        </Box>
        
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>          
          {pages.map((page) => (
            <Button
              key={page}
              onClick={()=>handleCloseNavMenu(page)}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {page}
            </Button>
          ))}
        </Box>

        <Box sx={{ flexGrow: 0, flexDirection:'row', display:'flex', alignItems:'center' }}>
          <Box sx={{mr: {md: 5, xs:10}, flexDirection:'row', justifyContent:'space-between'}}>
            <Typography sx={{display: {xs:'inline-block'}}} variant='caption' >Version : {process.env.REACT_APP_VERSION}</Typography>
            <Typography sx={{display: {xs:'none', md:'inline-block'}}} variant='caption' > - {process.env.REACT_APP_HASH}</Typography>
          </Box>
          <Tooltip title="Open settings">
            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'end'}}>
              <Box sx={{mr:2, textAlign:'right'}}>
                <Typography sx={{fontSize:'0.9em'}}>{user?.u_name ?? ''}</Typography>
                <Typography sx={{fontSize:'0.6em'}}>{user?.u_email ?? ''}</Typography>
              </Box>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar 
                  alt={user !== null ? user.u_name : 'None'} 
                  src={user !== null && user.u_photo ? base_url + user.u_photo : "/static/images/avatar/2.jpg"} 
                />
              </IconButton>
            </Box>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={()=>handleCloseUserMenu(setting)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </Container>
  </AppBar>
  )
}

export default Navbar