import React, {useState} from 'react'
import { Box, Button, Typography } from '@mui/material'
import { AugTable } from '../../../../components/Table'
import { TFAMode } from '../../../../Forms/components/interface'
import AvatarShow from '../../../../components/Table/components/AvatarShow'
import { format } from 'date-fns'

const ViewEstimations = (props: {job ?: string}) => {  
  const [type,setType] = useState<TFAMode>('SteadyState')

  return (
    <Box>
      <Box sx={{display:'flex',justifyContent:'center'}}>
        <Button sx={{mx:1}} variant={type === 'SlugTracking' ? 'contained' : 'outlined'} onClick={()=>setType('SlugTracking')}>Slug Tracking</Button>
        <Button sx={{mx:1}} variant={type === 'Pigging' ? 'contained' : 'outlined'} onClick={()=>setType('Pigging')}>Pigging</Button>
        <Button sx={{mx:1}} variant={type === 'SteadyState' ? 'contained' : 'outlined'} onClick={()=>setType('SteadyState')}>Steady State</Button>
      </Box>
      <Box>
      <AugTable 
      columnChooser
      allowResizing
      allowReordering
      dataManagerUrl={{
        url : 'ml/estimation.php'
      }}
      query={[
        {param:'action',value:'GET_ESTIMATION_TABLE'},
        {param:'type',value:type},
        {param:'job', value : props.job ?? ''}
      ]}
      columns={[
        {headerText:'ID',field:'f_id', width:40, isPrimaryKey:true},
        {headerText:"Creator", field:'creator_name', template: (a:any) => <AvatarShow data={a} nameSrc='creator_name' photoSrc='creator_photo' />},
        {headerText:'Job Name',field:'j_name',},
        {headerText:'Date',field:'f_dateCreated',template:(a:any) => <Typography>{format(new Date(a.f_dateCreated),'yyyy-MM-dd hh:mm aa')}</Typography>},
        {headerText:'GLR (sm3/sm3)',field:'f_glr'},
        {headerText:'Flow Rate (kg/h)',field:'f_flow_rate'},
        {headerText:'Horizontal Length (m)',field:'x_length'},
        /* {headerText:'Roughness (mm)',field:'f_roughness'}, */
        {headerText:'I.D. (mm)',field:'f_int_dia'},
        {headerText:'Surge Estimate (m3)',field:'f_surge'},
        {headerText:'ML Accuracy (R-Squared)',field:'mlv_accuracy'},
      ]}
    />
    </Box>
  </Box>
  )
}

export default ViewEstimations