import { Box, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../App'
import { toast } from 'react-toastify'
import { POST, base_url, getBlob } from '../../../components/utils/fetch'
import UserProfileForm from '../../../Forms/UserProfile'
import { format } from 'date-fns'
// import {format} from "date-fns"

type TUserProfile = {
  estimation : number,
  learning : number,
  training : number
}

const UserProfile = () => {
  const {user,modify} = useContext(UserContext)
  const [defaultValues, setDefaultValues] = useState<null|any>(null)
  const [userData, setUserData] = useState<TUserProfile|null>(null)

  useEffect(()=> {
    if (user !== null) {
      if (user?.u_photo !== null) {
        getBlob((base_url ?? '') + "/" + user.u_photo)
        .then(data => {
          setDefaultValues({
            name : user?.u_name,
            email : user?.u_email,
            email_old : user?.u_email,
            picture: data
          })
        }).catch(err => {
          setDefaultValues({
            name : user?.u_name,
            email : user?.u_email,
            email_old : user?.u_email,
          })
        })
      } else {
        setDefaultValues({
          name : user?.u_name,
          email : user?.u_email,
          email_old : user?.u_email,
        })
      }
    }
  },[user])
  useEffect(()=>{
    POST('user/stats.php', {action: "USER_PROFILE", u_id : user === null ? 0 : user.u_id})
    .then(response => {
      setUserData(response.data)
    })
  },[user])
  
  const updateUser = (a:any) => new Promise<any>((resolve, reject) => {
    toast.info("Updating User...")
    modify(a).then((res) => {
      // console.log("res", res)
      if (res === true) {
        toast.success("User data Sucessfully Modified!")
      } else {
        if (res === "USER_NOT_EXIST") {
          toast.error("Something wrong with the original email address; It does not exist! Contact Admin for more info")
        } else if (res === "EMAIL_EXISTS") {
          toast.error("The new email address already exists in the system. Try another email address instead!")
        } else {toast.error(`Something went wrong! Error : ${res}`)}
      }
      resolve({status:true, data:res})
    })
  })

  return (
    <Box  sx={{
      display: 'flex',
      flexDirection:'row',
      padding:'10px',
      height:'100%',
      width:'100%'
    }}>
      {/* <Paper elevation={3} sx={{width:'40%', padding:'10px', marginRight:'5px'}}>
        <AvatarUpload name={user === null ? '' : user.u_name} fileSrc={''} /> 
      </Paper> */}
      <Paper elevation={3} sx={{flex:2, width:'100%', padding:'10px', marginRight:'5px'}}>
        <Box sx={{width:'90%'}}>
        {defaultValues === null ? <Typography>Loading...</Typography> : 
        <UserProfileForm onSubmit={updateUser} defaultValues={defaultValues} />}
        </Box>
      </Paper>
      <Paper elevation={3} sx={{flex:1, width:'100%',marginLeft:'5px'}}>
        <Typography py={2} pl={2} variant='h6'>Statistics</Typography>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell>No. Estimations Created</TableCell>
              <TableCell>{userData === null ? '' : userData.estimation}</TableCell>
            </TableRow>
            {user?.u_role === 'ADMIN' && <><TableRow>
              <TableCell>No. Learning Sets Created</TableCell>
              <TableCell>{userData === null ? '' : userData.learning}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>No. ML Training Models Created</TableCell>
              <TableCell>{userData === null ? '' : userData.training}</TableCell>
            </TableRow></>}
            <TableRow>
              <TableCell>Last Login</TableCell>
              <TableCell>{user?.u_lastLogin ? format(new Date(user.u_lastLogin),'yyyy-MM-dd hh:mm:ss') : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Created On</TableCell>
              <TableCell>{user?.u_dateCreated ? format(new Date(user.u_dateCreated),'yyyy-MM-dd') : ''}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        
      </Paper>
    </Box>
  )
}

export default UserProfile 