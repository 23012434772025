import React from 'react'
import CreateTrainingBatch from '../../../../../../Learning/Train/New'

const RetrainML = (props: any) => {
  return (
    <div>
      <CreateTrainingBatch ml_name={props.ml_name} lockType={props.ml_type} ml_id={props.ml_id} currentVersionCount={props.versions} />
    </div>
  )
}

export default RetrainML