import { TUser } from '../../interfaces/user';
import { isDev } from './devCheck';
import download from "downloadjs"

export const base_url = isDev() ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD

export const securePOST = <T = any>(
  url: string,
  body: any,
  user: TUser,
  isJSON: boolean = true,
  outputType: 'json' | 'string' | 'blob' = 'json') => new Promise<{ data: T, status: boolean }>((resolve, reject) => {
    POST(url, { ...body, key: user.key }, isJSON, outputType)
      .then(response => resolve(response))
      .catch(err => reject(err))
  })

export const uploadFile = (file: File) => new Promise<false | string>(resolve => {
  const fileData = new FormData()
  fileData.append('file', file)
  fetch(`${base_url}/file/profile.php`, {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
    },
    body: fileData
  }).then(res => {
    if (res.status >= 200 && res.status < 300) {
      return res.json()
    } else { return { data: false } }
  }).then(res => resolve(res.data))
})

export const getBlob = (url: string, filename?: string) => new Promise<false | File>((resolve, reject) => {
  fetch(url, {
    method: "GET",
    headers: {
    }
  }).then(res => res.blob())
    .then(res => {
      const file = new File([res], filename ?? 'file-get')
      resolve(file)
    }).catch(err => reject(err))
})

export const POST = <T = any,>(url: string, body: any, isJSON: boolean = true, outputType: 'json' | 'string' | 'blob' | 'file_download' = 'json') => new Promise<{ data: T, status: boolean }>((resolve, reject) => {
  let headers: any = {}
  if (isJSON) { headers['Content-Type'] = 'application/json' }
  if (outputType === 'file_download') {
    // headers['Access-Control-Expose-Headers'] = '*'
    // headers['Access-Control-Allow-Headers'] = '*'
  }
  try {
    fetch(
      `${base_url}/${url}`,
      {
        method: "POST",
        headers: headers,
        body: isJSON ? JSON.stringify(body) : body
      }
    ).then(async (res) => {
      if (res.status >= 200 && res.status <= 300) {
        try {

          const returnData =
            outputType === 'json' ? await res.clone().json() :
              outputType === 'string' ? await res.clone().text() :
                ['blob','file_download'].includes(outputType) ? await res.clone().blob() :
                  await res.arrayBuffer()

          if (outputType === 'file_download') {    
            const contentDisposition = res.headers.get('Content-Disposition')
            const fileName = contentDisposition?.split(";")[1].substring(10)
            console.log('[downloadCheck] ', ...res.headers)
            console.log('[downloadCheck] content disposition - ',contentDisposition)        
            console.log('[downloadCheck] fileName - ',fileName)        
            download(returnData,fileName, 'application/octet-stream')
            // var file = window.URL.createObjectURL(returnData);
            // window.location.assign(file);
            return resolve({status:true, data:returnData})
          }
          return resolve({ status: returnData.status ?? true, data: returnData.data !== undefined ? returnData.data : returnData })
        } catch (err: any) {
          // console.log("[Error in POST] - err", err)
          // console.log("Data - ", await res.text())
          return resolve({ status: false, data: err })
        }
      }
      else { reject(JSON.stringify({ status: res.status, data: await res.clone().text(), statusText: res.statusText })) }
    })
  } catch (err: any) {
    // console.log("[Error in fetch] - err", err)
    return resolve({ status: false, data: err })
  }
})

export const uploadMLModel = (file: File) => new Promise(async (resolve) => {
  const fileData = new FormData()
  fileData.append('file', file)
  fileData.append('action','IMPORT')
  await fetch(`${base_url}/ml/exportML.php`, {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
    },
    body: fileData
  })

  resolve(true)
})
