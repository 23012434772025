import React, { useContext } from 'react'
import Box from "@mui/material/Box/Box"
import { Typography } from '@mui/material'
import { UserContext } from '../../../../../App'
import ViewLearning from '../../../../FA/Learning/View'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewEstimations from '../../../../FA/Estimator/View'

const JobDetails = (props: any) => {
  const {user} = useContext(UserContext)
  return (
    <Box py={2} width='100%' sx={{backgroundColor:'#1976d2', overflowX: 'auto'}}>
      <Box width={'98%'} px={1}>
        <Typography color="white" variant='h6'>Details On Job {props.j_name}</Typography>
        {user?.u_role === 'ADMIN' && <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="#1976d2" variant='h6'>Learning Rows Created</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ViewLearning job={props.j_id} />
            </AccordionDetails>
          </Accordion>}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="#1976d2" variant='h6'>Estimation Rows Created</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ViewEstimations job={props.j_id} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )
}

export default JobDetails