import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { DropzoneUploader, Form, Line, SubmitButton, TableList } from 'rhf-modular-wrapper'
import { IStandardForm } from '../../components/interface'
import { TOlgaFiles } from '../../../interfaces/files'
import * as Yup from "yup"
import ButtonWrapper from '../../components/templates/ButtonWrapper'

export type TBaseFileUpload = {file_name : string, file_upload: any, file_type ?: TOlgaFiles}

export type TFileUploaderReturnObject = TBaseFileUpload | {files: TBaseFileUpload}[]

interface IFileUploaderV1 extends IStandardForm<TFileUploaderReturnObject> {
  isMultiple ?: boolean,
  fileType ?: TOlgaFiles
}

const FileUploaderV1 = (props: IFileUploaderV1) => {
  const BaseSchema = useMemo(()=> Yup.object({
    file_name: Yup.string().max(64, "The file name should be no more than 64 characters !"),
    file_upload : Yup.mixed().test(
      'file-type',
      `Only ${props.fileType} files allowed!`,
      (val) => {
        // console.log("[val] - ",val)
        if (val.length === 0) {
          return true
        }  else {

          const _ext = val[0].name.split('.').pop()
          
          return _ext === props.fileType
        }
      }
    ).required("Please Upload a File!")
  }),[props.fileType])

  const FormSchema = useMemo(()=> props.isMultiple ? Yup.array().of(BaseSchema) : BaseSchema,[BaseSchema, props.isMultiple])

  return (
    <Form onSubmit={props.onSubmit} defaultValues={props.defaultValues} yupSchema={FormSchema} buttonWrapper={ButtonWrapper}>
      {
        props.isMultiple ?
        <Box>
          <TableList 
          name="files"
          items={[
            {name: 'file_name'},
            {name: 'file_upload', type:'dropzone'},
            {name : 'file_type', readOnly: true}
          ]} 
        /> 
        </Box>: <Box>
          <Box>
            <Line name="file_name" label="File Name" helperText="A Name for the Uploaded File, or blank for default name" />
          </Box>
          <Box>
            <DropzoneUploader name="file_upload" label="Upload File" helperText="Upload the File here" />
          </Box>
        </Box>
      }
      
      <SubmitButton>Upload File</SubmitButton>
    </Form>
  )
}

export default FileUploaderV1