import React from "react";
import Uploader from "../../components/Uploader";

// const yupSchema = Yup.object({
//   files: Yup.array().of(
//     Yup.mixed().test(
//       "file-type",
//       "FILES NEEDS TO BE OF TYPE .TAB, .GENKEY, .TPL, OR .PPL ONLY!",
//       (value) =>
//         ["genkey", "tab", "tpl", "ppl"].includes(value.name.split(".").pop())
//     )
//   ),
// });

const SimpleUploader = () => {
  return (<div style={{marginTop:'15px'}}>
    <Uploader 
    name="files" 
    label="Upload Files Here" 
    helperText="Only .tab, .genkey, and .tpl files are allowed!" 
    allowedExtensions='.tab, .genkey, .tpl '
  />
  </div>);
};

export default SimpleUploader;
