import { Avatar, Chip } from '@mui/material'
import React from 'react'
import { base_url } from '../../utils/fetch'

interface IAvatarShow { 
  data: {[key:string] : any},
  photoSrc : string,
  nameSrc : string
}

const AvatarShow = (props: IAvatarShow) => {
  const photo = props.data[props.photoSrc] ?? null
  const name = props.data[props.nameSrc] ?? null

  return (
    <Chip avatar={<Avatar alt={name} src={photo ? base_url + photo : null} />} label={name} variant='outlined' />
  )
}

export default AvatarShow