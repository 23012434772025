import { toast } from "react-toastify"
import { POST } from "../utils/fetch"
import { Box, LinearProgress, Typography } from "@mui/material"
import { ContentHeaders } from "../../Pages/FA/component/LearningViewer"

const parseHandlerFunction = (file_name:string) => new Promise<boolean>(async (resolve) => {
  if (file_name === '') {return resolve(false)}
  // Set toast to initialize loading progress
  const toastID = toast.loading(<Box  sx={{textAlign:'center'}}>
    <ContentHeaders>Parsing/Regenerating</ContentHeaders>
    <Typography>File : {file_name}</Typography>
  </Box>, {isLoading: true})

  let retValue = null
  
  try {
    retValue = await POST('file/index.php', {action: 'REGENERATE_CACHE', fileName: file_name, reset:true})
  } catch (err) {
    toast.update(toastID, {
      render: `Something went wrong! Error : ` + JSON.stringify(err),
      type: 'error',
      isLoading: false,
      autoClose: 3000,
    })

    return resolve(false)
  }


  while (retValue.data.status === false) {
    toast.update(toastID, {
      render: <Box sx={{textAlign:'center'}}>
        <ContentHeaders>Parsing Progress</ContentHeaders>
        <Typography>{Number(retValue.data?.data?.current_line ?? 0).toLocaleString()} / {Number(retValue.data?.data?.total_lines ?? 0).toLocaleString()}</Typography>
        <LinearProgress variant="determinate" value={100 * Number(retValue.data?.data?.current_line ?? 0) / Number(retValue.data?.data?.total_lines ?? 0)} />
      </Box>,
      type: 'info',
      isLoading: true,
      autoClose: false,
    })
    try {
      retValue = await POST('file/index.php', {action: 'REGENERATE_CACHE', fileName: file_name})
    } catch (err) {
      toast.update(toastID, {
        render: `Something went wrong! Error : ` + JSON.stringify(err),
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      })

      return resolve(false)
    }
  }

  toast.update(toastID, {
    render: `File ${file_name} parsed and cached successfully!`,
    type: 'success',
    isLoading: false,
    autoClose: 3000,
  })

  return resolve(true)

})  

export default parseHandlerFunction