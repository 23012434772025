import { Button } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ISubmitButton } from 'rhf-modular-wrapper'

const ButtonWrapper = (props: ISubmitButton) => {
  const {formState: {isSubmitting}} = useFormContext()
  return (
    <Button type="submit" variant="contained">{isSubmitting ? 'Submitting...' : (props.label ?? props.children ?? 'Submit Form')}</Button>
  )
}

export default ButtonWrapper