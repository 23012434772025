import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box/Box';
import RecalculateSurgeVolumeForm from '../../../../../../../../Forms/RecalculateSurgeVolume/v1';
import { toast } from 'react-toastify';
import { POST } from '../../../../../../../../components/utils/fetch';
import { TStandardPromise } from '../../../../../../../../Forms/components/interface';
import { UserContext } from '../../../../../../../../App';
import { TMLReturn } from '../../../..';
import SurgePlotTab from './components/SurgePanel';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

interface IActionTabs {
  data : TMLReturn | null

}
const ActionTabs = (props: IActionTabs) => {
  const {user} = React.useContext(UserContext)
  const [outlets, setOutlets] = React.useState<string[]>(['x_load_x'])
  const [value, setValue] = React.useState(0);

  
  React.useEffect(()=>{
    if (props.data === null) {setOutlets(['x_load_x'])}
    else {
      POST('ml/learning.php',{tpl: props.data.tpl, action: 'PREPARE_POSSIBLE_TPL_OUTPUTS'})
      .then(response => {
        if (response.data.status) {setOutlets(response.data.data)}
      })
    }    
  },[props.data])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const defaultValues = React.useMemo(()=>{
    if (props.data === null) {return {}}
    else {
      return {
        massFlow : props.data.fl_flow_rate,
        glr : props.data.fl_glr,
        dor : props.data.fl_dor,
        pipe : {
          length : props.data.x_length,
          roughness : props.data.fl_roughness,
          int_diameter : props.data.fl_int_dia
        },
        outletLabel : props.data.fl_outlet_label ?? undefined
      }
    }
  },[props.data])

  const handleRecalculate = (a:any) => new Promise<TStandardPromise>((resolve, reject) => {
    const toastId = toast.info('Calculating...',{isLoading: false, autoClose: false})
    POST('ml/learning.php',{
      fl_data : props.data,
      formData : a,
      user : user,
      action:'RECALCULATE_SURGE'})
    .then((response) => {
      if (response.data.status) {
        toast.update(toastId, {
          type: 'success',
          render : `The Calculation was successful! New Surge Value : ${Number(response.data.data.surge).toFixed(3)} m3`,
          autoClose : 2000,
          isLoading: false
        })
      } else {
        // console.log("[Error]-",response.data)
        toast.update(toastId, {
          type: 'error',
          render : `An Error Occured! : ${response.data.data}`,
          autoClose : 2000,
          isLoading: false
        })
      }

      resolve(response)
    })
    
  })

  return (    
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Actions"  />
          <Tab label="Surge Plot"  />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>                    
        <Box>
          <Typography sx={{textAlign:'center', fontWeight:'bold',mT:2,padding:'10px 0px'}}>Actions</Typography>
          <Box sx={{display:'flex',alignItems:'center',justifyContent: 'center'}}>
            <Box sx={{width:'50%', padding:'10px',background:'gainsboro', borderRadius:'5px'}}>
              <Typography variant="h6" sx={{marginTop:'10px',marginBottom:'10px'}}>Recalculate Surge Volume</Typography>
              <RecalculateSurgeVolumeForm outlets={outlets} onSubmit={handleRecalculate} defaultValues={defaultValues} />
            </Box>
          </Box>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SurgePlotTab data={props.data} />
      </CustomTabPanel>
    </Box>
  )
}

export default ActionTabs