import { Typography } from '@mui/material'
import React from 'react'

const Page404 = () => {
  return (
    <div>
      <Typography>This is a Page that does not or has not existed yet! Please check the URL again or try again.</Typography>
    </div>
  )
}

export default Page404