import { Box } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Navbar from './components/NavBar'
import SideBar from './components/SideBar'
import { UserContext } from '../../App'

interface IRootPage { 
}

const RootPage = (props: IRootPage) => {
  const {user} = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const allowedLocations = ['/job/view','/fa/estimation/new','/fa/estimation/history',"/user/profile","user/logout"]
    if (user !== null) {
      if (user.u_role !== 'ADMIN') {
        if (allowedLocations.filter( x => x === location.pathname).length === 0) {
          navigate('/')
        }
      }
    }
  },[location.pathname])//eslint-disable-line 
  
  return (
    <Box sx={{width:'100vw',height:'100vh'}}>
      {/* NavBar */}    <Navbar />
      <Box sx={{display: 'flex', width:'100vw', overflowY:'hidden', height:'calc(100% - 60px)'}}>
        {/* SideBar */}   <SideBar />

        {/* Content */}   <Box sx={{p:2, width:'100%',overflowX:'auto'}}><Outlet /></Box>
      </Box>

      {/* Footer ?  */} {/* <Footer /> */}
    </Box>
  )
}

export default RootPage