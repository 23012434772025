import React from 'react'
import SimpleUploader from '../../../Forms/FileUploader/SimpleUploader';

const FileAdd = () => {
  
  return (
    <SimpleUploader />
  )
}

export default FileAdd