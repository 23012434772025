import React, {useState} from 'react'
import {Box, Chip} from "@mui/material"
import { AugTable } from '../../../components/Table'
import AvatarShow from '../../../components/Table/components/AvatarShow'
import UserAction from './components/UserAction'
import ActionModal from './components/ActionModal'

const UserList = () => {
  const [actionType, setActionType] = useState<{user: any, type: null|'change_pass'|'update'|'delete'|'change_permission'}>({user: null, type: null})
  return (
    <Box>
      <AugTable 
        columnChooser
        allowResizing
        allowReordering
        dataManagerUrl={{
          url : 'user/auth.php'
        }}
        query={[
          {param:'action',value:'USER_TABLE'},
        ]}
        columns={[
          {headerText:'ID',field:'u_id', width:40, isPrimaryKey:true},
          {headerText:"User", field:'u_name', template: (a:any) => <AvatarShow data={a} nameSrc='u_name' photoSrc='u_photo' />},
          {headerText:"Email", field: 'u_email'},
          {headerText:"Account Type", field: 'u_role'},
          {headerText:"Estimations", field: 'u_est'},
          {headerText:"Learning", field: 'u_learn'},
          {headerText:"Model", field: 'u_model'},
          {headerText:"Last Login", field: 'u_lastLogin'},
          {headerText:"Date Created", field: 'u_dateCreated'},
          {headerText:'Allowed To Use', field: 'models', template: ModelTemplate},
          {headerText: "Action", field:'', template: (a:any) => <UserAction {...a} setActionType={setActionType} />}
        ]}
      />
      <ActionModal {...actionType} close={()=>setActionType({user:null, type:null})} />
    </Box>
  )
}

const ModelTemplate = (a:any) => {
  return a.models === 'All' ? <span>All</span> : <Box display='flex' flexDirection={'column'}>

    { 
      a.models === null ? 'No Allowed models!' :
      a.models.split('---').map((x:string) => <Chip sx={{my:1}} key={`${a.u_id}-${x}`} label={x} />)
    }
  </Box>
}

export default UserList