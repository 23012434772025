import { Box } from '@mui/material';
import React from 'react'

interface ISimpleBoxPlot {
  min: number;
  max: number;
  mean: number;
  median: number;
  std: number;
}

const SimpleBoxPlot = (props : ISimpleBoxPlot) => {
  const width = props.mean / (props.max- props.min) * 150
  // console.log('width', props, width)
  return (
    <Box sx={{borderRadius:'15', height:'10px',display:'flex',flexDirection:'row',alignItems:'center',width:'150px', minWidth:'150px', maxWidth:'150px'}}>
      <Box sx={{height:'10px',backgroundColor:'white',width:width, borderLeft:'1px solid black', borderRight:'1px solid red'}}></Box>
      <Box sx={{height:'10px',backgroundColor:'white',width:150-width, borderRight:'1px solid black'}}></Box>
    </Box>
  )
}

export default SimpleBoxPlot