import { Chip, Fade, Paper, Popper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'

interface IPopperTable {
  data : {
    fl_id : number,
    fl_dor : string,
    fl_flow_rate : string,
    fl_genkey_id:number
    fl_glr: number,
    fl_int_dia: string,
    fl_j_id: number,
    fl_name: string,
    fl_outlet_label: string, 
    fl_roughness: number, 
    fl_surge: string,
    fl_tab_id: number,
    fl_tpl_id: number,
    fl_type: string,
    fl_wc: string,
    genkey: string,
    j_name: string,
    tab:string,
    tpl: string,
    x_length: string
  }

}

const PopperTable = (props: IPopperTable) => {
  const {palette} = useTheme()
  const [open, setOpen] = useState(false)
  const [tableOpen, setTableOpen] = useState(false)
  const chipRef = useRef<any>()
  return (
    <span style={{marginRight:'15px',marginTop:'15px'}}>
    <Chip onMouseEnter={()=>setOpen(true)} onMouseLeave={()=>setOpen(false)} ref={chipRef} label={props.data.fl_id} />
    <Popper sx={{zIndex:99999}} open={open || tableOpen} anchorEl={chipRef.current} placement={'right'} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{p:3}} onMouseEnter={()=>setTableOpen(true)} onMouseLeave={()=>setTableOpen(false)}>
              <TableContainer>
                <TableHead>
                  <TableRow>
                    <TableCell>Param</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow><TableCell>JOB</TableCell><TableCell>{props.data.j_name}</TableCell></TableRow>
                  <TableRow><TableCell>WC</TableCell><TableCell>{props.data.fl_wc}</TableCell></TableRow>
                  <TableRow><TableCell>GLR (sm3/sm3)</TableCell><TableCell>{props.data.fl_glr}</TableCell></TableRow>
                  <TableRow><TableCell>FLOW RATE (kg/h)</TableCell><TableCell>{props.data.fl_flow_rate}</TableCell></TableRow>
                  <TableRow><TableCell>DOR (m3/h)</TableCell><TableCell>{props.data.fl_dor}</TableCell></TableRow>
                  <TableRow ><TableCell sx={{fontWeight:'bold',color:palette.error.main}}>Surge (m3)</TableCell><TableCell sx={{fontWeight:'bold',color:palette.error.main}}>{props.data.fl_surge}</TableCell></TableRow>
                  <TableRow sx={{backgroundColor: palette.primary.main}}><TableCell sx={{color:'white', textAlign:'center'}} colSpan={2}>PIPE</TableCell></TableRow>
                  <TableRow sx={{textAlign:'center'}}><TableCell sx={{fontWeight:'bold'}}>Length (m)</TableCell><TableCell sx={{fontWeight:'bold'}}>I.D (mm)</TableCell></TableRow>
                  <TableRow sx={{textAlign:'center'}}><TableCell>{props.data.x_length}</TableCell><TableCell>{props.data.fl_int_dia}</TableCell></TableRow>
                  <TableRow sx={{backgroundColor: palette.primary.main}}><TableCell sx={{color:'white', textAlign:'center'}} colSpan={2}>FILES USED</TableCell></TableRow>
                  <TableRow><TableCell>GENKEY</TableCell><TableCell>{props.data.genkey}</TableCell></TableRow>
                  <TableRow><TableCell>TAB</TableCell><TableCell>{props.data.tab}</TableCell></TableRow>
                  <TableRow><TableCell>TPL</TableCell><TableCell>{props.data.tpl}</TableCell></TableRow>
                </TableBody>
              </TableContainer>
            </Paper>
          </Fade>
        )}
      </Popper>
    </span>
  )
}

export default PopperTable