import React from 'react'
import Dialog from '@mui/material/Dialog/Dialog'
import Box from '@mui/material/Box/Box'
import Button from '@mui/material/Button/Button'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import ViewMLDetails from './components/ViewMLDetails'
import RetrainML from './components/RetrainML'
import { TMLMain } from '../..'

interface IMLModal {
  open : boolean,
  onClose: Function,
  setChosenML: Function,
  data : {ml: TMLMain, type: 'view' | 'retrain'} | null
}

const MLModal = (props: IMLModal) => {
  return (
    <Dialog open={props.open} onClose={()=>props.onClose()} maxWidth='xl' fullWidth>
      <DialogTitle>ML Index Actions {props.data !== null ? `[ ${props.data.ml.ml_name} ]` : ''}</DialogTitle>
      <DialogContent>
        <Box>
          <Button sx={{mr:4}} onClick={()=>props.setChosenML((prev:any) => ({...prev, type: 'view'}))} variant={props.data?.type === 'view' ? 'contained' : 'outlined'}>View ML Summary</Button>
          <Button sx={{mr:4}} onClick={()=>props.setChosenML((prev:any) => ({...prev, type: 'retrain'}))} variant={props.data?.type === 'retrain' ? 'contained' : 'outlined'}>Retrain ML (Version {Number(props.data?.ml.versions) + 1})</Button>
        </Box>
        {
          props.data === null ? null : 
          props.data.type === 'view' ? <ViewMLDetails {...props.data.ml} /> :
          props.data.type === 'retrain' ? <RetrainML {...props.data.ml} /> :
          null
        }
      </DialogContent>
    </Dialog>
  )
}

export default MLModal