import React from 'react'
import { Box, Dialog, DialogContent, DialogTitle, Table, TableCell, TableBody, TableRow, Typography } from '@mui/material'
import { TMLReturn } from '../..'
import BathymetryPlot from './components/BathymetryPlot'
import ActionTabs from './components/ActionTabs'

interface IItemViewer {
  data : TMLReturn | null
  close: () => void
}

const ItemViewer = (props: IItemViewer) => {
  const tableGenerator = (headers : string[], data:any[], extraCols ?: (null|any)[][]) => {
    console.group("Generating table")
    // console.log("Headers : ",headers)
    // console.log("Data : ",data)
    console.groupEnd()
    return <Table>
      <TableBody>
        {headers.map((x,i) => (<TableRow key={`itm-${x.replace(' ','')}-${i}`}>
          <TableCell>{x}</TableCell>
          <TableCell sx={{color : data[i] === null  ? 'red' : 'black'}}>{
            data[i] === null ? 
            'Not available or Deleted' :
            isNaN(Number(data[i])) ? ( data[i]) : Number(data[i]).toFixed(2)}</TableCell>
          {extraCols?.[i][0] !== null && extraCols?.[i].map((col,colI) => <TableCell key={`itm-${x.replace(' ','')}-${i}-xtra-${colI}`}>{isNaN(Number(col)) ? col : Number(col).toFixed(2)}</TableCell>)  }
        </TableRow>))}
      </TableBody>
    </Table>
  }

  return (
    <Dialog maxWidth="lg" fullWidth open={props.data !== null} onClose={()=>props.close()}>
      <DialogTitle>View Item</DialogTitle>
      {
        props.data !== null && <DialogContent>
          <Box>
            {
              tableGenerator(
                ['Job','Tag','Tab File','Genkey File','TPL File','SurgeVolume (m3)'],
                [props.data.j_name,props.data.fl_name, props.data.tab,props.data.genkey,props.data.tpl,props.data.fl_surge],
                [
                  [null], 
                  [null], 
                  [tableGenerator(['GLR'],[props.data.fl_glr])], 
                  [tableGenerator(['Mass Flow (kg/h)','Pipe Length (m)','Roughness (mm)','Internal Diamter (mm)'],[props.data.fl_flow_rate,props.data.x_length,props.data.fl_roughness, props.data.fl_int_dia])], 
                  [null],
                  [null],
                ]
              )
            }
          </Box>
          <Box>
            <Typography sx={{textAlign:'center', fontWeight:'bold', borderTop:'1px solid grey',mT:2,padding:'10px 0px'}}>Bathymetry</Typography>
            <BathymetryPlot id={props.data.fl_id} />
          </Box>
          <Box>
            <ActionTabs data={props.data} />
          </Box>
      </DialogContent>
      }
    </Dialog>
  )
}

export default ItemViewer