import React, { useState } from 'react'
import { TFAMode } from '../../../../Forms/components/interface'
import { Box, Button } from '@mui/material'
import { AugTable } from '../../../../components/Table'
import ItemViewer from './components/ItemViewer'
import Typography from "@mui/material/Typography/Typography"
import AvatarShow from '../../../../components/Table/components/AvatarShow'
import { format } from 'date-fns'
import { POST } from '../../../../components/utils/fetch'
import { toast } from 'react-toastify'

export type TMLReturn = {
  j_name : string,     
  fl_j_id : number,  
  fl_id : number, 
  fl_name: string,
  fl_type : string, 
  fl_dor : number,
  genkey : string,
  tab : string,
  tpl : string,
  fl_glr : number,
  fl_flow_rate : number,
  fl_surge : number,
  x_length : number,
  fl_dateCreated : string,
  fl_p_diff: number,
  fl_roughness : number,
  fl_int_dia : number,
  fl_outlet_label ?: string,
}

const ViewLearning = (props: {job ?: any}) => {
  const [type,setType] = useState<TFAMode>('SteadyState')
  const [selectedItem, setSelectedItem] = useState<null|TMLReturn>(null)

  const fixLearning = () => new Promise(async (resolve) => {
    const ids = await POST("ml/learning.php",{action:"LIST_LEARNING",})
    const promises:any[] = []
    if (ids.data.length > 0) {
      const data:{fl_id:number,fl_name:string}[] = ids.data
      data.forEach(x => {
        toast.loading(`Fixing ${x.fl_name}...`,{toastId:x.fl_id,isLoading:true})
        promises.push(POST("ml/learning.php",{action:"REPAIR_LEARNING", fl_id: x.fl_id})
        .then(y => toast.update(x.fl_id, {render:`Update Complete for item ${x.fl_name}!`})))
      })      
    }
    
    await Promise.allSettled(promises)
     
  })

  return (
    <Box>
      <ItemViewer data={selectedItem} close={()=>setSelectedItem(null)} />
      <Box sx={{my:5}}>
        <Button variant="contained" onClick={()=>fixLearning()}>Fix Learning</Button>
      </Box>
      <Box sx={{ my:2, display:'flex',justifyContent:'center'}}>
        <Button sx={{mx:1}} variant={type === 'SlugTracking' ? 'contained' : 'outlined'} onClick={()=>setType('SlugTracking')}>Slug Tracking</Button>
        <Button sx={{mx:1}} variant={type === 'Pigging' ? 'contained' : 'outlined'} onClick={()=>setType('Pigging')}>Pigging</Button>
        <Button sx={{mx:1}} variant={type === 'SteadyState' ? 'contained' : 'outlined'} onClick={()=>setType('SteadyState')}>Steady State</Button>
      </Box>
      <Box>
      <AugTable 
      setCurrentActiveRow={setSelectedItem}
      columnChooser
      allowResizing
      allowReordering
      dataManagerUrl={{
        url : 'ml/learning.php'
      }}
      query={[
        {param:'action',value:'GET_LEARNING_TABLE'},
        {param:'type',value:type},
        {param:'job', value : props.job ?? ''}
      ]}
      columns={[
        {headerText:'ID',field:'fl_id', width:40, isPrimaryKey:true},
        {headerText:"Added On", field:'fl_dateCreated',template:(a:any) => <Typography>{format(new Date(a.fl_dateCreated),'yyyy-MM-dd hh:mm aa')}</Typography>},
        {headerText:"Job", field:'j_name'},
        {headerText:"Creator", field:'creator_name', template: (a:any) => <AvatarShow data={a} nameSrc='creator_name' photoSrc='creator_photo' />},
        {headerText:'Tag',field:'fl_name'},
        {headerText:'Genkey File',field:'genkey'},
        {headerText:'Tab File',field:'tab'},
        {headerText:'TPL File',field:'tpl'},
        {headerText:'DOR (m3/hr)',field:'fl_dor'},
        {headerText:'GLR (sm3/sm3)',field:'fl_glr'},
        {headerText:'Flow Rate (kg/h)',field:'fl_flow_rate'},
        {headerText:'Surge Volume (m3)',field:'fl_surge'},
        {headerText:'Horizontal Length (m)',field:'x_length'},
        {headerText:'Pressure Difference (barg)',field:'fl_p_diff'},
      ]}
    />
      </Box>
    </Box>
  )
}

export default ViewLearning