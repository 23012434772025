import React from 'react'
import { Button} from "@mui/material"
import { POST } from '../../components/utils/fetch'

const TestPage = () => {
  const create = () =>{
    POST('ml/test.php',{action: "CREATE_CSV"})
  }
  const train = () => {
    POST('ml/test.php',{action: "TRAIN"}).then((res) => {
      // console.log(res)
    })
  }

  const predict = () => {
    POST('ml/test.php',{action: "PREDICT"}).then((res) => {
      // console.log(res)
    })
  }
  return (
    <div>
      <Button onClick={()=>create()}>Create CSV</Button>
      <Button onClick={()=>train()}>Train Model</Button>
      <Button onClick={()=>predict()}>Predict Test Values</Button>
    </div>
  )
}

export default TestPage