import React, { useState } from 'react'
import { IStandardForm, TStandardPromise } from '../../interface'
import LoadingScreen from 'react-loading-screen';
import ButtonWrapper from '../ButtonWrapper';
import { Form, SubmitButton } from 'rhf-modular-wrapper';
import Box from '@mui/material/Box/Box';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import "./wrapper.css"
import Avatar from '../../Avatar';
import PressureConverterInput from '../../Converter/Pressure';
import { isDev } from '../../../../components/utils/devCheck';
import { useFormContext } from 'react-hook-form';

interface IFormWrapper<T=any> extends IStandardForm<T> {
  [key:string|number] : any,
  children : any | any[],
  loadingCaption ?: string
  submitCaption : string,
  schema ?: any
}

const FormWrapper = (props: IFormWrapper) => {
  const [loading, setLoading] = useState<boolean>(false)

  const submitter = (a:any) => new Promise<TStandardPromise>((resolve, reject) => {
    console.log('[SubmittingForm] - submitter', a)
    setLoading(true)

    props.onSubmit(a)
      .then((r)=>{setLoading(false); return resolve(r)})
      .catch((r)=>{setLoading(false); return resolve(r)})
  })

  return (<LoadingScreen loading={loading} text={props.loadingCaption ?? 'SUBMITTING FORM...'}>  
    <Form 
      yupSchema={props.schema} 
      onSubmit={submitter} 
      defaultValues={props.defaultValues} 
      buttonWrapper={ButtonWrapper}
      elements={{
        avatar: Avatar,
        pressureConverter : PressureConverterInput
      }}
    >
      <Stack spacing={2}>

      {isDev() && <ErrorChecker /> }
      {props.children}
      <Box sx={{marginTop:'10px'}}><SubmitButton>{props.submitCaption}</SubmitButton></Box>
    </Stack>
  </Form>
  </LoadingScreen>)
}

const ErrorChecker = () => {
  const {formState: {errors}} = useFormContext()
  console.log('[errors] - ',errors)
  const errorNames = Object.keys(errors)
  return <Stack spacing={2} >
    {
      errorNames.map(name => <Typography color='red' key={name}>{(errors[name]?.message === undefined ? JSON.stringify(errors[name]) : errors[name]?.message) as any}</Typography>)
    }
  </Stack>
}

export default FormWrapper