import React, { useRef, useContext } from 'react'
import { TMLMain } from '../../../../../..'
import { AugTable } from '../../../../../../../../../../components/Table'
import { Grid } from '@syncfusion/ej2-react-grids'
import {Box} from "@mui/material"
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AvatarShows from '../../../../../../../../../../components/Table/components/AvatarShows'
import { POST } from '../../../../../../../../../../components/utils/fetch'
import { UserContext } from '../../../../../../../../../../App'
import { toast } from 'react-toastify'

const MLVersionView = (props: TMLMain) => {
  const tableRef = useRef<Grid>(null)
  const resizeTable = () => {
    if (tableRef.current !== undefined && tableRef.current !== null) {
      tableRef.current.autoFitColumns([])
    }
  }

  return (
    <Box width='100%'>
      <AugTable 
        dataBound={resizeTable}
        ref={tableRef}
        allowResizing
        dataManagerUrl={{url:`ml/learning.php`}}
        query={[
          {param:'action', value:'VIEW_MODEL_VERSIONS'},
          {param:'ml_id', value: props.ml_id.toString()}
        ]}
        columns={[
          {field: 'mlv_version', headerText: "Version"},
          {field: 'estimation_count', headerText: "Used in"},
          {field: 'allowed_names', headerText: "Allowed Users (Predictor Accounts)", template: (a) => <AvatarShows data={a} nameSrc='allowed_names' photoSrc='allowed_photos' />},
          {field: 'mlv_accuracy', headerText: "Accuracy"},
          {field: 'training_rows', headerText: "No Training Sets"},
          {field: 'tools', headerText: "Actions", template: (a) => <MLViewActions {...a} ml_id={props.ml_id} />},
        ]}
      />
    </Box>
  )
}

const MLViewActions = (props: any) => {
  const {user} = useContext(UserContext)

  const exportML = () => new Promise(async(resolve, reject) => {
    toast.loading('Exporting Data to File...',{toastId: `export-${props.mlv_id}`, autoClose:false})
    await POST("ml/exportML.php",{action:"EXPORT", ml_id: props.ml_id, version: props.mlv_version, u_id: user?.u_id},true,'file_download')
    toast.update(`export-${props.mlv_id}`,{render:`Export Complete!`, autoClose:2000, type:'success', isLoading:false})
  })

  return <Box>
    <Tooltip title="Export File">
      <IconButton onClick={()=>exportML()}>
        <FileDownloadIcon />
      </IconButton>
    </Tooltip>
  </Box>
}

export default MLVersionView