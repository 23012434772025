import React, { useCallback, useContext, useState } from 'react'
import { POST } from '../../../../components/utils/fetch'
import { UserContext } from '../../../../App'
import { toast } from 'react-toastify'
import LoadingOverlay from 'react-loading-overlay-ts';
import FaLearningBatchForm, { TFaLearningBatchReturnObject } from '../../../../Forms/FaLearningBatch/v1'
import LearningViewer from '../../component/LearningViewer';

//eslint-disable-next-line 
type TLearningReturnV1 = {
  data : {
    glr: {value: number, unit: string},
    wc: {value: number, unit: string}
  },
  job : string,
  learningID : string,
  pipes : {    
    avg_diameter: number,
    avg_roughness: number,
    "fullLength(x)" : number,
    "fullLength(y)" : number,
    ids : string[]
  },
  result : {  
    deltas : number[],
    surge_volume : number
  }
}


const FaLearningNewBatch = () => {
  const [overlay, setOverlay] = useState<boolean>(false)
  const {user} = useContext(UserContext)
  const onSubmit = useCallback((a:TFaLearningBatchReturnObject)=> new Promise<{status:boolean, data:any}>((resolve, reject) => {
    setOverlay(true)
    const toastID = toast.loading("Adding Data... Please Give Me a Moment....",{isLoading: true, autoClose: false})
    const promises:any = []
    a.data.forEach(data => {
      promises.push(POST<any>( "ml/learning.php", { data:data, user : user, action:"ADD_LEARNING" }))
    })

    Promise.all(promises).then((d)=>{
      // console.log("[promises completed] d",d)
      d.forEach(res => {   
        toast.success((props) => <LearningViewer {...props} {...res} />)
      })
      toast.update(toastID, {type: "success" , render: "All Batch Data Added!", isLoading: false, autoClose: 2000})     
      resolve({status:true, data:null})
      setOverlay(false)
    }).catch(err => {
      toast.error(`An error occured! Error : ${err.location ?? err.message ?? typeof err === 'object' ? JSON.stringify(err) : err}`)
      toast.update(toastID, {type: "error" , render: "An error occured!", isLoading: false, autoClose: 2000})     
      resolve({status:false, data:null})
    })
  }),[]) //eslint-disable-line

  return (
    <LoadingOverlay active={overlay} spinner text="Parsing and Generating Surge Volume ....">
      <FaLearningBatchForm onSubmit={onSubmit}/>
    </LoadingOverlay>
  )
}

export default FaLearningNewBatch