import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import { IInputsBaseProps, InputWrapper, Line, Select } from 'rhf-modular-wrapper';
import { useFormContext } from 'react-hook-form';

const PressureOptions = [
  {value:'barg',label:'Barg'},
  {value:'bar',label:'Bar'},
  {value:'psig',label:'Psig'},
  {value:'psi',label:'Psi'},
  {value:'pa',label:'Pa'},
  {value:'kpa',label:'KPa'},
]

const PressureConverterInput = (props: IInputsBaseProps) => {
  const {watch, setValue, getValues} = useFormContext()

  // const inputWatch = watch(props.name)
  const unitWatch = watch(`${props.name}-unit`)
  const [lastUnit, setLastUnit] = useState<TPressureUnits>(unitWatch ? unitWatch.value : 'barg')

  useEffect(()=>{
    if (unitWatch !== null && unitWatch !== undefined) {
      setValue(props.name, convertPressure(Number(getValues(props.name)),lastUnit,unitWatch.value ))
      setLastUnit(unitWatch.value)
    } else {
      setValue(`${props.name}-unit`, {value:'barg', label:'Barg'})
    }
  },[unitWatch]) // eslint-disable-line

  return (
    <InputWrapper {...props}>
      <Box className="fused-box" sx={{display:'flex', flexDirection:'row',width:'100%'}}>
        <Line name={props.name} noLabel/>
        <Select name={`${props.name}-unit`} options={PressureOptions} defaultValue={PressureOptions[0]} noLabel />
      </Box>
    </InputWrapper>
  )
}



type TPressureUnits = 'barg' | 'psig' | 'bar' | 'psi' | 'pa' | 'kpa'
type BasePressure = 'barg' 

const basePressure:BasePressure = 'barg'

export const setPressure = (value: number, unit: TPressureUnits, name: string, setValue: Function) => {
  console.log(`[Setting Pressure] Value : ${value} unit : ${unit} name: ${name}. `,setValue)
  setValue(name, Number(value).toPrecision(5))
  setValue(`${name}-unit`, PressureOptions.filter(x => x.value === unit)[0])
}
export const convertPressure = (toConvert: number, fromUnit: TPressureUnits, toUnit: TPressureUnits) => {
  const conversionValues:Record<TPressureUnits, Record<TPressureUnits,(val:number) => number>> = {
    barg : {
      barg: (val:number) => val,
      psig : (val:number) => val * 14.5038,
      bar : (val:number) => val + 1.01325,
      psi : (val:number) => (val + 1.01325) * 14.5038,
      pa : (val:number) => (val + 1.01325) * 100000,
      kpa : (val:number) => (val + 1.01325) * 100
    },
    pa : { barg : (val:number) => (val / 100000) - 1.01325  , psig: (val)=>val, bar: (val)=>val,psi: (val)=>val,pa: (val)=>val,kpa: (val)=>val },
    kpa : { barg : (val:number) => (val / 100) - 1.01325  , psig: (val)=>val, bar: (val)=>val,psi: (val)=>val,pa: (val)=>val,kpa: (val)=>val },
    psig : { barg : (val:number) => val / 14.5038  , psig: (val)=>val, bar: (val)=>val,psi: (val)=>val,pa: (val)=>val,kpa: (val)=>val },
    bar : { barg : (val:number) => val - 1.01325 , psig: (val)=>val, bar: (val)=>val,psi: (val)=>val,pa: (val)=>val,kpa: (val)=>val },
    psi : { barg : (val:number) => (val / 14.5038) - 1.01325 , psig: (val)=>val, bar: (val)=>val,psi: (val)=>val,pa: (val)=>val,kpa: (val)=>val }
  }
  // If both from and to units are the same, just return the value
  if (fromUnit === toUnit) { return toConvert }

  if (fromUnit === undefined || toUnit === undefined) { return toConvert }

  // Convert value to the base
  console.log(`[convertPressure] - toConvert : ${toConvert}, from : ${fromUnit}, to : ${toUnit}`)
  const base_value = fromUnit === basePressure ? 
    toConvert : 
    conversionValues[fromUnit].barg(toConvert) 

  console.log(`[convertPressure] - baseValue at barg : ${base_value}`)

  // Convert from base to new value
  return conversionValues.barg[toUnit](base_value).toFixed(3)
}

export default PressureConverterInput