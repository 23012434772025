import React from 'react'
import { TMLReturn } from '../../../../../..'
import { POST } from '../../../../../../../../../../components/utils/fetch';
import { Box } from '@mui/material';
import SurgePlot from './components/SurgePlot';
import FAAddDOR from '../../../../../../../../../../Forms/FAAddDOR';
import { toast } from 'react-toastify';
import { UserContext } from '../../../../../../../../../../App';

interface ISurgePlot {
  data : TMLReturn | null,
}

const SurgePlotTab = (props: ISurgePlot) => {
  const {user} = React.useContext(UserContext)
  const [data ,setData] = React.useState([])

  React.useEffect(()=>{
    if (props.data !== null) {
      POST("ml/learning.php",{action: 'GET_SURGE_PLOT', p_id : props.data.fl_id})
      .then(response => {
        if (response.data.status) {
          setData(response.data.data.map((x:any) => ({surge : Number(x.surge), dor : Number(x.dor)})))
        }
      })
    }
  },[props.data])

  const onSubmit = (a:any) => new Promise<{status:true, data:null}>((resolve, reject) => {
    // Calculate points
    const min = Number(a.dor_start)
    const max = Number(a.dor_end)
    const steps = Number(a.dor_steps)
    const eachStep = (max - min) / (steps - 1)
    let completed = 0;

    const points = []
    const toastID = toast.info(<div><p>Min : {min}, Max : {max}, Steps : {steps}</p><p>Completed {completed} / {steps}</p></div>, {isLoading: true, autoClose: false})
    for (let i = 0; i < steps; i++) {
      if (i === 0) {
        points.push(
          POST('ml/learning.php',{
            formData : {dor : min},
            fl_data: props.data,
            user : user,
            action : "RECALCULATE_SURGE"
          }))
      }
      else if (i === steps - 1) {
        points.push(POST('ml/learning.php',{
          formData : {dor : max},
          fl_data: props.data,
          user : user,
          action : "RECALCULATE_SURGE"
        }).then(res => {
          if (res.status && res.data.status) {
          } else {

          }
        }))
      }
      else {
        points.push(POST('ml/learning.php',{
          formData : {dor : min + (eachStep * i) },
          fl_data: props.data,
          user : user,
          action : "RECALCULATE_SURGE"
        }))
      }
    }

    points.forEach(p => p.then(() => {
      completed++;
      toast.update(toastID,{
        render: <div><p>Min : {min}, Max : {max}, Steps : {steps}</p><p>Completed {completed} / {steps}</p></div>,        
      })
    }));

    Promise.all(points).then(res => {
      toast.update(toastID, {
        render : 'Surge Values Calculated!',
        type: 'success',
        isLoading:false,
        autoClose: 2000
      })   
      return POST("ml/learning.php",{action: 'GET_SURGE_PLOT', p_id : props.data === null ? 0 : props.data.fl_id})
    }).then(response => {
      if (response.data.status) {
        setData(response.data.data.map((x:any) => ({surge : Number(x.surge), dor : Number(x.dor)})))
      }
    }).catch(err => {
      toast.update(toastID, {
        render : 'Something went wrong!',
        type: 'error',
        isLoading:false,
        autoClose: 2000
      })
      // console.log('Surge Calculation Error : ',err)
    })

    resolve({status:true, data:null})
  })
  
  return (
    <Box>
      <Box><FAAddDOR onSubmit={onSubmit} /></Box>
      <Box><SurgePlot data={props.data} plotData={data} /></Box>
    </Box>
  )
}

export default SurgePlotTab