import React, { useRef, useState } from 'react'
import { AugTable } from '../../../../../components/Table'
import { Box, Button, Typography } from '@mui/material'
import { Grid } from '@syncfusion/ej2-react-grids'
import ConfirmTrainModal from './components/ConfirmTrainModal'
import { format } from 'date-fns'

//eslint-disable-next-line
type TLearningTableV1 = {
  j_name : string,
  fl_id : number,
  fl_type : 'steadystate' | 'pigging' | 'slugtracking',
  genkey : string,
  tab : string,
  tpl : string,
  ppl : string,
  fl_glr : number,
  fl_flow_rate : number,
  fl_surge : number,
  x_length : number,
}

interface ICreateTrainingBatch {
  lockType ?: 'Pigging'|'SteadyState'|'SlugTracking',
  ml_id ?: number,
  ml_name ?: string,
  currentVersionCount ?: number | string
}

const CreateTrainingBatch = (props: ICreateTrainingBatch) => {
  const ref = useRef<Grid>(null)
  const [type, setType] = useState<'Pigging'|'SteadyState'|'SlugTracking'>(props.lockType ?? 'SlugTracking')
  // const [selected, setSelected] = useState<any[]>([])
  const [isAll, setIsAll] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false)

  return (
    <div>
      <p>Select Set(s) for Training / Validation Purposes</p>
      {props.ml_id && <p>Retraining MLID : {props.ml_id} [{props.lockType}] - Version {props.currentVersionCount ? Number(props.currentVersionCount) + 1 : 'New'}</p>}
      <Box sx={{my:3}}>
        <Button sx={{}} variant="contained" onClick={()=>setOpenModal(true)}>Add Training</Button>
        <Button sx={{mx:2}} color="secondary" variant="contained" onClick={()=>{setOpenModal(true); setIsAll(true)}}>Use All</Button>
      </Box>
      {props.lockType === undefined && <Box sx={{my:3, display:'flex',justifyContent:'center'}}>
        <Button sx={{mx:3}} variant={type === "SlugTracking" ? "contained":"outlined"} onClick={()=>setType("SlugTracking")}>Slug Tracking</Button>
        <Button sx={{mx:3}} variant={type === "Pigging" ? "contained":"outlined"} onClick={()=>setType("Pigging")}>Pigging</Button>
        <Button sx={{mx:3}} variant={type === "SteadyState" ? "contained":"outlined"} onClick={()=>setType("SteadyState")}>Steady State</Button>
      </Box>}
      <ConfirmTrainModal name={props.ml_name} version={props.currentVersionCount} parent_ml={props.ml_id} show={openModal} onHide={()=>{setOpenModal(false); setIsAll(false)}} tableRef={ref} type={type} isAll={isAll} />
      <AugTable 
      // rowSelected={rowSelected}
      // rowDeselected={rowDeselected}
      ref={ref}
      columnChooser
      gridDirectSettings={{
        selectionSettings : {
          checkboxOnly: true,
          type: 'Multiple',
          persistSelection: true
        }
      }}
      allowResizing
      allowReordering
      dataManagerUrl={{
        url : 'ml/learning.php'
      }}
      query={[
        {param:'action',value:'GET_LEARNING_TABLE'},
        {param:'type',value:type},
      ]}
      columns={[
        {type:'checkbox',field:'', width:40},
        {headerText:'ID',field:'fl_id', width:40, isPrimaryKey:true},
        {headerText:'Type',field:'fl_type'},
        {headerText:'Genkey File',field:'genkey'},
        {headerText:'Tab File',field:'tab'},
        {headerText:'TPL File',field:'tpl'},
        {headerText:'GLR (sm3/sm3)',field:'fl_glr'},
        {headerText:'Flow Rate (kg/h)',field:'fl_flow_rate'},
        {headerText:'Surge Volume (m3)',field:'fl_surge'},
        {headerText:'Horizontal Length (m)',field:'x_length'},
        {headerText:'Date Created',field:'fl_dateCreated',template:(a:any) => <Typography>{format(new Date(a.fl_dateCreated),'yyyy-MM-dd hh:mm aa')}</Typography>},
      ]}
    /> 
    </div>
  )
}

export default CreateTrainingBatch

