import React, {useRef, useEffect, useState} from 'react'
import Box from "@mui/material/Box/Box"
import Button from "@mui/material/Button/Button"
import { AugTable } from '../../../../components/Table'
import { Grid } from '@syncfusion/ej2-react-grids'
import { useNavigate, useParams } from 'react-router-dom'
import MLActions from './components/MLActions'
import MLModal from './components/MLModal'
import AvatarShows from '../../../../components/Table/components/AvatarShows'
import { uploadMLModel } from '../../../../components/utils/fetch'
import { toast } from 'react-toastify'

export type TMLMain = {
  creator_name:  string,
  creator_photo : string,
  estimation_count: number,
  ml_id : number,
  ml_name: string,
  ml_type : string,
  versions : string
}

const ViewMachineLearning = () => {
  const [chosenML, setChosenML] = useState<any|null>(null)
  const navigate = useNavigate()
  const {type} = useParams()

  const tableRef = useRef<Grid>(null)

  useEffect(()=>{
    if (type === undefined) {
      navigate('/fa/learning/train/view/SteadyState')
    }
  },[type, navigate])

  const resizeTable = () => {
    if (tableRef.current !== undefined && tableRef.current !== null) {
      tableRef.current.autoFitColumns([])
    }
  }

  return (
    <Box>
      <Box my={2}>
        <ImportModelButton />
      </Box>
      <Box my={3} display='flex' flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <Button variant={type === 'SteadyState' ? 'contained' : 'outlined' } onClick={()=>navigate('/fa/learning/train/view/SteadyState')} sx={{mx:2}}>Steady State</Button>
        <Button variant={type === 'Pigging' ? 'contained' : 'outlined' } onClick={()=>navigate('/fa/learning/train/view/Pigging')} sx={{mx:2}}>Pigging</Button>
        <Button variant={type === 'SlugTracking' ? 'contained' : 'outlined' } onClick={()=>navigate('/fa/learning/train/view/SlugTracking')} sx={{mx:2}}>Slug Tracking</Button>
      </Box>
      <AugTable 
        dataBound={resizeTable}
        ref={tableRef}
        allowResizing
        dataManagerUrl={{url:`ml/learning.php`}}
        query={[
          {param:'action', value:'VIEW_MODELS'},
          {param:'fileType', value: type ?? ''}
        ]}
        columns={[
          {field: 'ml_id', headerText:'ID'},
          {field: 'ml_name', headerText:'Name'},
          {headerText:"Version Creator(s)", field:'creator_names', template: (a:any) => <AvatarShows data={a} nameSrc='creator_names' photoSrc='creator_photos' />},          
          {field: 'estimation_count', headerText:'Use Count'},
          {field: "versions", headerText:'Versions Created'},
          {field: 'action', headerText:'Actions', template: (a) => <MLActions setChosenML={setChosenML} {...a} />}
        ]}
      />
      <MLModal open={chosenML !== null} onClose={()=>setChosenML(null)} data={chosenML} setChosenML={setChosenML} />
    </Box>
  )
}

const ImportModelButton = () => {
  const inputRef = useRef<HTMLInputElement>()

  const onClick = () => {
    inputRef.current?.click()
  }

  const uploadFile = async (e:any) => {

    const file = e.target.files
    console.log('file ', file[0])
    const name:string = file[0].name
    const extension = name.split('.').reverse().slice(0,2)
    if (extension[0] === 'bak' && extension[1] === 'model') {

      toast.loading(`Importing model ${file[0].name} ... Please wait`, {toastId : "import", isLoading : true, autoClose: false})
      const data:any = await uploadMLModel(file[0])
      console.log('data', data)
      if (data === true) {
        toast.update('import',{render: `Model ${name} added successfully!`, autoClose: 2000, isLoading: false, type:'success'})
      } else {
        toast.update('import',{render: `Model ${name} could not be added!`, autoClose: 2000, isLoading: false, type:'error'})
      }
    } else {
      toast.error('File type not supported! Exported model files should have the extension model.bak !')
    }

    e.target.value = null
  }

  return <div style={{textAlign:'center'}}>
    <Button color='error' variant='contained' onClick={()=>onClick()}>Import a Model</Button>
    <input onChange={uploadFile} ref={inputRef as any} type="file" id="my_file_input" style={{display:"none"}}/>
  </div>
}

export default ViewMachineLearning