import { POST } from "./fetch"

export const list_users = (user_type: ('Admin' | 'Predictor')[], tag: string, callback: Function) => new Promise<any>(resolve => {
  POST("user/auth.php", { action: "LIST_USERS", tag: tag, type: user_type })
    .then(response => {
      // console.log("Response : ",response)
      if (response.status) {
        // console.log('Response list_users', response.data === null ? [] : response.data)
        callback(response.data === null ? [] : response.data)
      } else {
        callback([])
      }
    }).catch(err => {
      console.error(err)
      callback([])
    })
})

export const loadNonAdminUsers = (tag: string, callback: Function) => list_users(['Predictor'], tag, callback)

export const loadModels = (tag: string, callback: Function) => {
  POST('ml/estimation.php', { action: 'MODEL_OPTIONS', tag: tag })
    .then(response => {
      if (response.data) {
        callback(response.data ?? [])
      }
    })
}