import { Box, Table, TableHead, TableRow,TableCell, Typography, TableBody } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { POST } from '../../../../../../../../../components/utils/fetch'
import SimpleBoxPlot from './components/SimpleBox'

interface IValidationStation {
  rows : any[], // {fl_id}[]
  type: 'Pigging'| 'SteadyState' | 'SlugTracking',
  isAll ?:boolean
}

type Stats = {'min' : number, 'max' : number, 'mean' : number, 'median' : number, 'std' : number}
type StatsRows = 'glr' | 'fr' | 'dor' | 'len' | 'id' | 'pdiff'

const titles:Record<StatsRows,string> = {
  'glr' : 'GLR',
  'fr' : 'Flow Rate',
  'dor' : 'Draw off Rate',
  'len' : 'Pipe Length (m)',
  'id' : 'Internal Diameter (mm)',
  'pdiff' : 'Pressure Drop (barg)'
} 

const ValidationStation = (props: IValidationStation) => {
  const [stats, setStats] = useState<null|Record<StatsRows,Stats>>(null)

  useEffect(()=>{
    POST<Record<StatsRows,Stats>>('ml/learning.php',{action:'GET_DATA_STATS', type:props.type, isAll: props.isAll, ids: props.rows.map(x => x.fl_id), })
    .then(response => {
      if (response.status) {setStats(response.data)}
    })
  },[props.isAll, props.type, props.rows])

  return (
    <Box my={2} pt={4} sx={{backgroundColor:'#f9f9f9'}}>
      <Typography variant="h4" textAlign={'center'}>Data Parameters</Typography>
      {
        stats !== null && <ValidationTable stats={stats} />
      }
    </Box>
  )
}

export const ValidationTable = ({stats}: {stats: Record<StatsRows,Stats>}) => {
  return <Table>
  <TableHead>
    <TableRow>
      <TableCell>Parameter</TableCell>
      <TableCell>Min</TableCell>
      <TableCell>Mean</TableCell>
      <TableCell>Median</TableCell>
      <TableCell>Max</TableCell>
      <TableCell>Standard Deviation</TableCell>
      <TableCell>Skewness</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {
      Object.keys(stats).map((statskey) => {
        const min:number = Number((stats[statskey as StatsRows].min as any).replace(',',''))
        const max:number = Number((stats[statskey as StatsRows].max as any).replace(',',''))
        const median:number = Number((stats[statskey as StatsRows].median as any).replace(',',''))
        const mean:number = Number((stats[statskey as StatsRows].mean as any).replace(',',''))
        const std:number = Number((stats[statskey as StatsRows].std as any).replace(',',''))

        return <TableRow key={statskey}>
        <TableCell>{titles[statskey as StatsRows]} <SimpleBoxPlot {...{min,max,mean,median,std}} /></TableCell>
        <TableCell>{Number(min).toLocaleString()}</TableCell>
        <TableCell>{Number(mean).toLocaleString()}</TableCell>
        <TableCell>{Number(median).toLocaleString()}</TableCell>
        <TableCell>{Number(max).toLocaleString()}</TableCell>
        <TableCell>{Number(std).toLocaleString()}</TableCell>
        <TableCell>
          {(3 * (mean - median) / std).toFixed(2)}
        </TableCell>
      </TableRow>
      })
    }
    <TableRow>
    </TableRow>
  </TableBody>
</Table>
}

export default ValidationStation