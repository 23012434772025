import React, { useCallback, useEffect, useState } from 'react'
import { FormList, TSelectOption } from 'rhf-modular-wrapper';
import { IStandardForm } from '../../components/interface';
import { POST } from '../../../components/utils/fetch';
import SelectFileOrUploadv1 from "../../SelectFileOrUpload/v1"
import { useFormContext } from 'react-hook-form';
import FormWrapper from '../../components/templates/FormWrapper';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export type TFaLearningBatchReturnObject = {
  data : {
    tag: string;
    job: TSelectOption;
    mode: TSelectOption;
    tab: TSelectOption;
    genkey: TSelectOption;
    tpl: TSelectOption;    
    data: {
      glr : number,
      massflow : number,
      pipe_x_len : number,
      pipe_roughness : number,
      pipe_id : number,
    }
  }[]
};

interface IFaLearningBatchV1 extends IStandardForm<TFaLearningBatchReturnObject> {}

const emptyRow = {tag:"",job:{label:'',value:''},mode:{label:'Steady State',value:'Steady State'},tab:{label:'',value:0},genkey:{label:'',value:0},tpl:{label:'',value:0},glr:0,massflow: 0,pipe_x_len: 0,pipe_roughness: 0,pipe_id: 0}

const FaLearningBatchForm = (props: IFaLearningBatchV1) => {

  

  return (
    <FormWrapper submitCaption='Add Learning Data' onSubmit={props.onSubmit} defaultValues={{
      data:  [emptyRow],
      ...props.defaultValues
    }}>
      <DataSetter />
      <TableWrapper />
    </FormWrapper>
  )
}

const TableWrapper = () => {
    
  const loadJobs = useCallback(
    (key: string, callback: (a: TSelectOption[]) => void) =>
      new Promise((resolve, reject) => {
        POST<TSelectOption[]>("job/index.php", {
          action: "GET_JOB_SELECT",
          key: key ?? "",
        }).then((response) => {
          // console.log("[loadJobs] - ", response);
          callback(response.data);
        });
      }),
    []
  );

  return <FormList 
  name="data" 
  bodyTemplate={BodyTemplate}        
  items={[
    {label: 'Tag' ,type: 'text',name:'tag'},
    {label: 'Job' ,type: 'select_async',name:'job', defaultOptions:true, cacheOptions: true, loadOptions: loadJobs},
    {label: 'Mode' ,type: 'select',name:'mode', options:[{value:'SlugTracking', label: 'Slug Tracking'},{value:'SteadyState', label: 'Steady State'},{value:'Pigging', label: 'Pigging'}]},
    {label: 'Tab File' ,type: 'custom', name: 'tab', elem : (a:any) => <SelectFileOrUploadv1 name={a.name} label="Tab File" type="tab"/>},
    {label: 'Genkey File' ,type: 'custom', name: 'genkey', elem : (a:any) => <SelectFileOrUploadv1 name={a.name} label="Genkey File" type="genkey"/>},
    {label: 'TPL File' ,type: 'custom', name: 'tpl', elem : (a:any) => <SelectFileOrUploadv1 name={a.name} label="TPL File" type="tpl"/>},
    {label: 'GLR (sm3/sm3)' ,type: 'number', name: 'data.glr'},
    {label: 'Mass Flow (kg/hr)' ,type: 'number', name: 'data.massflow'},
    {label: 'X Length (m)' ,type: 'number', name: 'data.pipe_x_len'},
    /* {label: 'Pipe Roughness (mm)' ,type: 'number', name: 'data.pipe_roughness'}, */
    {label: 'Pipe ID (mm)' ,type: 'number', name: 'data.pipe_id'},
    {label: 'Pressure In' ,type: 'pressureConverter', name: 'data.p_in'},
    {label: 'Pressure Out' ,type: 'pressureConverter', name: 'data.p_out'},
    {label: 'Pressure Out' ,type: 'pressureConverter', name: 'data.p_drop'},
    {label: 'Position Labels' ,type: 'string', name: 'data.position_labels_tpl'},
  ]}
/>
}

// const useBodyTemplate = (index: number) => {
//   const {setValue, watch} = useFormContext()

//   const _watch = watch(`data.${index}`)

//   useEffect(()=>{
//     POST('ml/learning',{action:"BATCH_GET_PARAM",tab : _watch.tab ?? null,genkey : _watch.genkey ?? null,tpl : _watch.tpl ?? null})
//     .then((response) => {
//       if (response.status) {
//         const toChange = {..._watch}
//         let hasChange = false        

//         if (toChange['glr'] !== response.data.glr) {toChange['glr'] = response.data.glr; hasChange = true}
//         if (toChange['massFlow'] !== response.data.massFlow) {toChange['massFlow'] = response.data.massFlow; hasChange = true}
//         if (toChange['pipe_x_len'] !== response.data.pipe_x_len) {toChange['pipe_x_len'] = response.data.pipe_x_len; hasChange = true}
//         if (toChange['pipe_roughness'] !== response.data.pipe_roughness) {toChange['pipe_roughness'] = response.data.pipe_roughness; hasChange = true}
//         if (toChange['pipe_id'] !== response.data.pipe_id) {toChange['pipe_id'] = response.data.pipe_id; hasChange = true}
        
//         if (hasChange) {setValue(`data.${index}`,toChange)}
//       }
//     })
//   },[_watch.tpl, _watch.genkey, _watch.tab]) //eslint-disable-line
// } 

const BodyTemplate = (props: any) => {
  // useBodyTemplate(props.index)
  // console.log("[BodyTemplate] - ",props)

  // if ((props.fields.tab !== undefined && props.fields.tab.value > 0) && (props.fields.genkey !== undefined && props.fields.genkey.value > 0) && (props.fields.tpl !== undefined && props.fields.tpl.value > 0)) {

  //   POST('ml/learning',{action:"BATCH_GET_PARAM",tab : props.fields.tab ?? null,genkey : props.fields.genkey ?? null,tpl : props.fields.tpl ?? null})
  //   .then((response) => {
  //     if (response.status) {
  //       const toChange = {...props.fields}
  //       let hasChange = false        

  //       if (toChange['glr'] !== response.data.glr) {toChange['glr'] = response.data.glr; hasChange = true}
  //       if (toChange['massFlow'] !== response.data.massFlow) {toChange['massFlow'] = response.data.massFlow; hasChange = true}
  //       if (toChange['pipe_x_len'] !== response.data.pipe_x_len) {toChange['pipe_x_len'] = response.data.pipe_x_len; hasChange = true}
  //       if (toChange['pipe_roughness'] !== response.data.pipe_roughness) {toChange['pipe_roughness'] = response.data.pipe_roughness; hasChange = true}
  //       if (toChange['pipe_id'] !== response.data.pipe_id) {toChange['pipe_id'] = response.data.pipe_id; hasChange = true}
        
  //       if (hasChange) {setValue(`data.${props.index}`,toChange)}
  //     }
  //   })
  // }
  
  return <div style={{width: '95%'}}>
  <Box sx={{position:'relative', width: '95%', margin:'5px 2px', display:'flex', flexDirection: 'row', backgroundColor:'gainsboro', borderRadius: '5px', boxShadow:'0px 5px 5px 1px #0f0f0f7d', padding:'10px'}}>
    <div id={`loader-${props.index}`} data-name="undefined" style={{borderRadius:'5px',position:'absolute', width: '100%', height:'100%', top:0,left:0, opacity:0, transition: '0.4s all', display: 'none',justifyContent: 'center',alignItems: 'center',background: '#dfdfdf99',zIndex: 1}}>Getting Data ...</div>
    <Box><div data-index></div></Box>
    <Box sx={{width: '100%', display:'flex', flexDirection: 'column'}}>
      <Box sx={{width: '100%', display:'flex', flexDirection:'row'}}>
        <div style={{width: '100%'}} data-name="tag" ></div>
        <div style={{width: '100%'}} data-name="job" ></div>
        <div style={{width: '100%'}} data-name="mode" ></div>
      </Box>
      <Box className="f-w-100" sx={{width: '100%', display:'flex', flexDirection:'row'}}>
        <div style={{width: '100%'}} data-name="tab" ></div>
        <div style={{width: '100%'}} data-name="genkey" ></div>
        <div style={{width: '100%'}} data-name="tpl" ></div>
      </Box>
      <Box sx={{width: '100%', display:'flex', flexDirection:'row'}}>
        <div style={{width: '100%'}} data-name="data.glr" ></div>
        <div style={{width: '100%'}} data-name="data.massflow" ></div>
        <div style={{width: '100%'}} data-name="data.pipe_x_len" ></div>
        {/* <div style={{width: '100%'}} data-name="data.pipe_roughness" ></div> */}
        <div style={{width: '100%'}} data-name="data.pipe_id" ></div>
      </Box>
      <Box sx={{width: '100%', display:'flex', flexDirection:'row'}}>
        <div style={{width: '100%'}} data-name="data.p_in" ></div>
        <div style={{width: '100%'}} data-name="data.p_out" ></div>
        <div style={{width: '100%'}} data-name="data.p_drop" ></div>
      </Box>
      <Box sx={{width: '100%', display:'flex', flexDirection:'row'}}>
        <div style={{width: '100%'}} data-name="data.position_labels_tpl" ></div>
        <div id={`positionLabels-${props.index}`} style={{width: '100%'}} data-name="undefined" ></div>
      </Box>
    </Box>
    <Box sx={{width:'150px',display:'flex',flexDirection:'row'}}>
      <Button data-add><AddIcon sx={{color:'green'}} /></Button>
      <Button data-remove><RemoveIcon sx={{color:'red'}}/></Button>
    </Box>
  </Box>
</div>
}

const DataSetter = () => {
  const [pastFiles, setPastFiles] = useState<any[]>([])
  const {watch, setValue} = useFormContext()

  const dataWatch = watch<any>('data')
  // useEffect(()=>{
  //   setPastFiles(dataWatch ? dataWatch.map((x:any) => (x.tab?.value ?? 0) * (x.tpl?.value ?? 0) *  (x.genkey?.value ?? 0)) : [0])
  // },[])

  const files = dataWatch ? dataWatch.map((x:any) => (x.tab?.value ?? 0) * (x.tpl?.value ?? 0) *  (x.genkey?.value ?? 0)).reduce((a:number,b:number) => a + b,0) : 0
  // console.log("dataWatch" ,dataWatch)
  // console.log("files" ,files)

  useEffect(()=>{
    // console.log("[checking datawatch]")
    // console.log("pastFiles ",pastFiles)
    for (let i = 0; i < dataWatch.length; i++) {  
      if (
            (dataWatch[i].tab !== undefined && dataWatch[i].tab.value > 0) 
          && (dataWatch[i].genkey !== undefined && dataWatch[i].genkey.value > 0) 
          && (dataWatch[i].tpl !== undefined && dataWatch[i].tpl.value > 0) 
          && (pastFiles.length >= i+1 ? pastFiles[i] !== (dataWatch[i].tab.value * dataWatch[i].genkey.value * dataWatch[i].tpl.value) : true)
      ) {
        const loader = document.getElementById(`loader-${i}`)
        const positionLabels = document.getElementById(`positionLabels-${i}`)

        if (loader !== null) {
          loader.style.display = "flex"
          loader.style.opacity = "1"
        }
        POST('ml/learning.php',{action:"BATCH_GET_PARAM",tab : dataWatch[i].tab ?? null,genkey : dataWatch[i].genkey ?? null,tpl : dataWatch[i].tpl ?? null})
        .then((response) => {
          if (response.status) {
            const toChange = {...dataWatch[i]}
            let hasChange = false        
            
            if (toChange['data']['glr'] !== response.data.glr) {toChange['data']['glr'] = response.data.glr; hasChange = true}
            if (toChange['data']['massflow'] !== response.data.massflow) {toChange['data']['massflow'] = response.data.massflow; hasChange = true}
            if (toChange['data']['pipe_x_len'] !== response.data.pipe_x_len) {toChange['data']['pipe_x_len'] = response.data.pipe_x_len; hasChange = true}
            if (toChange['data']['pipe_roughness'] !== response.data.pipe_roughness) {toChange['data']['pipe_roughness'] = response.data.pipe_roughness; hasChange = true}
            if (toChange['data']['pipe_id'] !== response.data.pipe_id) {toChange['data']['pipe_id'] = response.data.pipe_id; hasChange = true}
            
            if (hasChange) {setValue(`data.${i}`,toChange)}            

            if (positionLabels !== null) {
              positionLabels.innerHTML = response.data.position_labels.length < 1 ? `No Labels Found!` : `<table style="width:100%">
                <tbody>
                  <tr>
                    <td>
                      <table style="width:100%;text-align:center;">
                        <tbody>
                          <tr><th>TPL Position Labels </th></tr>
                          ${response.data.position_labels.map((label :string) => `<tr><td>${label}<td></tr>`)} 
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>`
            }
            
            if (loader !== null) {
              loader.style.opacity = "0"
              loader.style.display = "none"
            }
          }
        })
      }
    }
    
    setPastFiles(dataWatch.map((x:any) => (x.tab?.value ?? 0) * (x.tpl?.value ?? 0) *  (x.genkey?.value ?? 0)))
  },[files]) //eslint-disable-line

  return <div></div>
}

export default FaLearningBatchForm