import { Avatar, Chip, Stack } from '@mui/material'
import React from 'react'
import { base_url } from '../../utils/fetch'

interface IAvatarShow { 
  data: {[key:string] : any},
  photoSrc : string,
  nameSrc : string
}

const AvatarShows = (props: IAvatarShow) => {
  const photo:string[] = (props.data[props.photoSrc] ?? []).split(',')
  const name:string[] = (props.data[props.nameSrc] ?? []).split(',')

  return (
    <Stack direction={'column'} spacing={1}>
      {
        name.map((x,i) => <Chip avatar={<Avatar alt={x} src={photo[i] ? base_url + photo[i] : undefined} />} label={name} variant='outlined' />)
      }
      

    </Stack>
  )
}

export default AvatarShows