import React, { useContext,useRef, useEffect, useMemo, useImperativeHandle } from "react";
import "./Tables.css";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  ContextMenu,
  Group,
  Inject,
  Page,
  Sort,
  Search,
  Toolbar,
  PdfExport,
  ExcelExport,
  Grid,
  DetailRow,
  Resize,
  ColumnChooser,
  Edit,
  Reorder,
} from "@syncfusion/ej2-react-grids";
import { DataManager, UrlAdaptor, Query } from "@syncfusion/ej2-data";
import { TablePropsInterface, TablePropsColInterface } from "./interface";
import { base_url } from "../utils/fetch";
import { UserContext } from "../../App";

export const AugTable = React.forwardRef<Grid,TablePropsInterface>((props: TablePropsInterface, ref) => {
  const {user} = useContext(UserContext)
  const augtable_ref = useRef<any>(Grid);
  useImperativeHandle(ref, () => augtable_ref.current);

  const _usedData = props.dataManagerUrl?.url
    ? new DataManager({
        adaptor: new UrlAdaptor(),
        insertUrl: props.dataManagerUrl?.insertUrl
          ? base_url + "/" + props.dataManagerUrl.insertUrl
          : undefined,
        removeUrl: props.dataManagerUrl?.removeUrl
          ? base_url + "/" + props.dataManagerUrl.removeUrl
          : undefined,
        updateUrl: props.dataManagerUrl?.updateUrl
          ? base_url + "/" + props.dataManagerUrl.updateUrl
          : undefined,
        url: props.dataManagerUrl?.url
          ? base_url + "/" + props.dataManagerUrl.url
          : undefined,
      })
    : props.data;

  const InjectedServices: any[] = [
    Filter,
    Page,
    Sort,
    Toolbar,
    Search,
    PdfExport,
    ExcelExport,
    ContextMenu,
    Resize,
  ];
  let ToolbarItems: string[] = ["Search", "PdfExport", "ExcelExport", "Print"];
  let presetEditOptions = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Normal",
  };
  let editOptions = {};

  // DataManager

  if (props.withToolbar === false) {
    ToolbarItems.splice(0, ToolbarItems.length);
  }

  if (props.detailsTemplate !== undefined) {
    InjectedServices.push(DetailRow);
  }

  if (props.allowGroup === true || props.allowGroup === undefined) {
    InjectedServices.push(Group);
  }

  if (props.allowReordering) {
    InjectedServices.push(Reorder);
  }

  if (props.columnChooser) {
    InjectedServices.push(ColumnChooser);
    ToolbarItems.push("ColumnChooser");
  }

  if (props.allowEditing) {
    InjectedServices.push(Edit);
    editOptions = {
      ...presetEditOptions,
      ...props.editOptions,
    };
    if (props.editToolbarItems) {
      ToolbarItems.push(...props.editToolbarItems);
    } else {
      ToolbarItems.push("Add", "Edit", "Delete", "Update", "Cancel");
    }
  }

  useEffect(() => {
    // logger.log(augtable_ref.current);
    if (augtable_ref.current !== null && augtable_ref.current !== undefined && augtable_ref.current.element !== undefined) { 
      augtable_ref.current.element.classList.add("e-clickable");
      augtable_ref.current.autoFitColumns();
    }
  }, [augtable_ref]);

  const table_id = useMemo(() => {
    // if (typeof props.setCurrentActiveRow !== undefined) {
    //   if (augtable_ref) {augtable_ref.current.classList.add("e-clickable")}
    // }
    return new Date().getTime();
  }, []);

  const tableClickHandler = (event: any) => {
    try {
      const rowInfo = augtable_ref.current !== null && augtable_ref.current.getRowInfo(event.target);
      if (rowInfo !== false && rowInfo?.rowData !== null && rowInfo?.rowData !== undefined) {
        let key = props.primaryKey ? props.primaryKey : Object.keys(rowInfo.rowData)[0];
        if (props.setCurrentActiveRow) {props.setCurrentActiveRow(props.primaryKey ? rowInfo.rowData[key as keyof typeof rowInfo['rowData']] : rowInfo.rowData);}
      }
    } catch(err) {
      return
    }
  };

  const prepareColumnDirectives = (
    cols: TablePropsColInterface[] | undefined
  ) => {
    return cols === undefined
      ? null
      : cols.map((col, i) => (
          <ColumnDirective {...col} key={`at-cd-${i}`}></ColumnDirective>
        ));
  };

  const toolbarClickHandler = (arg: any) => {
    if (augtable_ref && arg.item.id === `grid_${table_id}_pdfexport`) {
      augtable_ref.current !== null && augtable_ref.current.pdfExport({
        fileName:
          (props.file_export ?? `SYN_${new Date().getTime()}`) + ".pdf",
      });
    } else if (augtable_ref && arg.item.id === `grid_${table_id}_excelexport`) {
      augtable_ref.current !== null && augtable_ref.current.excelExport({
        fileName:
          (props.file_export ?? `SYN_${new Date().getTime()}`) + ".xlsx",
      });
    }
  };

  const dataBoundHandler = (args: any) => {
    if (augtable_ref.current !== null && augtable_ref.current !== undefined) {
      augtable_ref.current.autoFitColumns()
    }
    if (props.dataBound) {
      props.dataBound(args)
    }
  }

  const _query = useMemo(() => {
    const _queryInstance = new Query();
    _queryInstance.addParams('session_key',user?.key ?? '')
    _queryInstance.addParams('id',user?.u_id ? user.u_id.toString() : "")
    if (props.query) {
      props.query.forEach((item) => {
        _queryInstance.addParams(item.param, item.value);
      });
    }
    return _queryInstance;
  }, [props.query, user?.key, user?.u_id]);

  return (
    <GridComponent      
      {...props.gridDirectSettings}
      rowSelected={props.rowSelected}
      rowDeselected={props.rowDeselected}
      query={_query}
      dataBound={dataBoundHandler}
      dataSource={_usedData}
      onClick={tableClickHandler}
      id={`grid_${table_id}`}
      ref={augtable_ref}
      showColumnChooser={props.columnChooser}
      allowResizing={props.allowResizing}
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      allowFiltering={true}
      allowReordering={props.allowReordering}
      allowGrouping={props.allowGroup}
      queryCellInfo={props.customizeCell}
      toolbarClick={toolbarClickHandler}
      pageSettings={{ pageSize: 10, pageSizes: true }}
      detailTemplate={props.detailsTemplate}
      editSettings={editOptions}
      rowTemplate={props.rowTemplate}
      contextMenuItems={[
        "AutoFit",
        "AutoFitAll",
        "SortAscending",
        "SortDescending",
        "Copy",
        "Edit",
        "Delete",
        "Save",
        "Cancel",
        "PdfExport",
        "ExcelExport",
        "CsvExport",
        "FirstPage",
        "PrevPage",
        "LastPage",
        "NextPage",
      ]}
      groupSettings={{ showGroupedColumn: true }}
      toolbar={ToolbarItems}
      height="100%"
    >
      <ColumnsDirective>
        {prepareColumnDirectives(props?.columns)}
      </ColumnsDirective>
      <Inject services={InjectedServices} />
    </GridComponent>
  );
})
// export const AugTable = (props: TablePropsInterface) => {
//   const augtable_ref = useRef<any>(Grid);

//   const _usedData = props.dataManagerUrl?.url
//     ? new DataManager({
//         adaptor: new UrlAdaptor(),
//         insertUrl: props.dataManagerUrl?.insertUrl
//           ? base_url + "/" + props.dataManagerUrl.insertUrl
//           : undefined,
//         removeUrl: props.dataManagerUrl?.removeUrl
//           ? base_url + "/" + props.dataManagerUrl.removeUrl
//           : undefined,
//         updateUrl: props.dataManagerUrl?.updateUrl
//           ? base_url + "/" + props.dataManagerUrl.updateUrl
//           : undefined,
//         url: props.dataManagerUrl?.url
//           ? base_url + "/" + props.dataManagerUrl.url
//           : undefined,
//       })
//     : props.data;

//   const InjectedServices: any[] = [
//     Filter,
//     Page,
//     Sort,
//     Toolbar,
//     Search,
//     PdfExport,
//     ExcelExport,
//     ContextMenu,
//     Resize,
//   ];
//   let ToolbarItems: string[] = ["Search", "PdfExport", "ExcelExport", "Print"];
//   let presetEditOptions = {
//     allowEditing: true,
//     allowAdding: true,
//     allowDeleting: true,
//     mode: "Normal",
//   };
//   let editOptions = {};

//   // DataManager

//   if (props.withToolbar === false) {
//     ToolbarItems.splice(0, ToolbarItems.length);
//   }

//   if (props.detailsTemplate !== undefined) {
//     InjectedServices.push(DetailRow);
//   }

//   if (props.allowGroup === true || props.allowGroup === undefined) {
//     InjectedServices.push(Group);
//   }

//   if (props.allowReordering) {
//     InjectedServices.push(Reorder);
//   }

//   if (props.columnChooser) {
//     InjectedServices.push(ColumnChooser);
//     ToolbarItems.push("ColumnChooser");
//   }

//   if (props.allowEditing) {
//     InjectedServices.push(Edit);
//     editOptions = {
//       ...presetEditOptions,
//       ...props.editOptions,
//     };
//     if (props.editToolbarItems) {
//       ToolbarItems.push(...props.editToolbarItems);
//     } else {
//       ToolbarItems.push("Add", "Edit", "Delete", "Update", "Cancel");
//     }
//   }

//   useEffect(() => {
//     // logger.log(augtable_ref.current);
//     if (augtable_ref.current.element) {
//       augtable_ref.current.element.classList.add("e-clickable");
//       augtable_ref.current.autoFitColumns();
//     }
//   }, [augtable_ref]);

//   const table_id = useMemo(() => {
//     // if (typeof props.setCurrentActiveRow !== undefined) {
//     //   if (augtable_ref) {augtable_ref.current.classList.add("e-clickable")}
//     // }
//     return new Date().getTime();
//   }, []);

//   const tableClickHandler = (event: any) => {
//     const rowInfo = augtable_ref.current.getRowInfo(event.target);
//     // console.log("[rowInfo] - ",rowInfo)
//     if (rowInfo?.rowData !== null && rowInfo?.rowData !== undefined) {
//       let key = props.primaryKey ? props.primaryKey : Object.keys(rowInfo.rowData)[0];
//       if (props.setCurrentActiveRow) {props.setCurrentActiveRow(props.primaryKey ? rowInfo.rowData[key] : rowInfo.rowData);}
//     }
//   };

//   const prepareColumnDirectives = (
//     cols: TablePropsColInterface[] | undefined
//   ) => {
//     return cols === undefined
//       ? null
//       : cols.map((col, i) => (
//           <ColumnDirective {...col} key={`at-cd-${i}`}></ColumnDirective>
//         ));
//   };

//   const toolbarClickHandler = (arg: any) => {
//     if (augtable_ref && arg.item.id === `grid_${table_id}_pdfexport`) {
//       augtable_ref.current.pdfExport({
//         fileName:
//           (props.file_export ?? `SYN_${new Date().getTime()}`) + ".pdf",
//       });
//     } else if (augtable_ref && arg.item.id === `grid_${table_id}_excelexport`) {
//       augtable_ref.current.excelExport({
//         fileName:
//           (props.file_export ?? `SYN_${new Date().getTime()}`) + ".xlsx",
//       });
//     }
//   };

//   const _query = useMemo(() => {
//     const _queryInstance = new Query();
//     if (props.query) {
//       props.query.forEach((item) => {
//         _queryInstance.addParams(item.param, item.value);
//       });
//     }
//     return _queryInstance;
//   }, [props.query]);

//   return (
//     <GridComponent
//       {...props.gridDirectSettings}
//       query={_query}
//       dataSource={_usedData}
//       onClick={tableClickHandler}
//       id={`grid_${table_id}`}
//       ref={augtable_ref}
//       showColumnChooser={props.columnChooser}
//       allowResizing={props.allowResizing}
//       allowPaging={true}
//       allowSorting={true}
//       allowPdfExport={true}
//       allowExcelExport={true}
//       allowFiltering={true}
//       allowReordering={props.allowReordering}
//       allowGrouping={props.allowGroup}
//       queryCellInfo={props.customizeCell}
//       toolbarClick={toolbarClickHandler}
//       pageSettings={{ pageSize: 10, pageSizes: true }}
//       detailTemplate={props.detailsTemplate}
//       editSettings={editOptions}
//       rowTemplate={props.rowTemplate}
//       contextMenuItems={[
//         "AutoFit",
//         "AutoFitAll",
//         "SortAscending",
//         "SortDescending",
//         "Copy",
//         "Edit",
//         "Delete",
//         "Save",
//         "Cancel",
//         "PdfExport",
//         "ExcelExport",
//         "CsvExport",
//         "FirstPage",
//         "PrevPage",
//         "LastPage",
//         "NextPage",
//       ]}
//       groupSettings={{ showGroupedColumn: true }}
//       toolbar={ToolbarItems}
//       height="100%"
//     >
//       <ColumnsDirective>
//         {prepareColumnDirectives(props?.columns)}
//       </ColumnsDirective>
//       <Inject services={InjectedServices} />
//     </GridComponent>
//   );
// };
