import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

interface ISubRootTemplate {
  header: string,
  subHeader ?: string,
  root : string,
  options: {name:string, url: string}[] 
}

const SubRootTemplate = (props: ISubRootTemplate) => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Box sx={{mt:2,mb:3}}>
      <Box sx={{display:'flex', width:'100%', flexDirection:'row', justifyContent: 'start', alignItems: 'center'}}>
        <Typography sx={{mr:1, fontSize:15,color:'#6393d0'}}>{props.header}</Typography>
        <Typography sx={{fontSize:13,color:'#616161',fontStyle:'italic'}}>{props.subHeader}</Typography>
      </Box>
      <Box>
        {
          location.pathname === props.root ? 
            <Box sx={{width:'100%',textAlign:'center',display:'flex',justifyContent: 'center'}}>
              {props.options.map((option, i) => <Button sx={{m:4}} key={`rb-${i}`} variant="contained" onClick={()=>navigate(option.url)} size="large">{option.name}</Button>)}
            </Box> : <Outlet/>
        }
      </Box>
    </Box>
  )
}

export default SubRootTemplate