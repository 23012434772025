import { Box, Typography } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

interface IRootTemplate {
  header: string, 
  subHeader ?: string,
  extraElem ?: {
    top ?: React.ReactNode,
    bottom ?: React.ReactNode
  }
}
const RootTemplate = (props: IRootTemplate) => {
  return (
    <Box>
      <Typography sx={{width:'100%',fontSize:30,color:'#185db6'}}>{props.header}</Typography>
      <Typography sx={{width:'100%',fontSize:14,color:'#616161',pb:2,borderBottom:'2px solid #113c74'}}>{props.subHeader}</Typography>
      {props.extraElem && props.extraElem.top && <Box>{props.extraElem.top}</Box>}
      <Box><Outlet /></Box>
      {props.extraElem && props.extraElem.bottom && <Box>{props.extraElem.bottom}</Box>}
    </Box>
  )
}

export default RootTemplate