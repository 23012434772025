import React, {useState, useEffect, useContext} from 'react'
import Box from "@mui/material/Box/Box"
import Typography from "@mui/material/Typography/Typography"
import CardStats from './components/CardStats'
import { FaBullseye, FaCalculator, FaDatabase, FaFolderOpen, FaGlobe, FaRobot, FaUsers } from 'react-icons/fa6'
import { blue, red, deepPurple } from '@mui/material/colors';
import { UserContext } from '../../App';
import { POST } from '../../components/utils/fetch';

type TDetails = {
  userCount : number,
  jobCreated : number,
  trainingSets: number,
  globalEstimations : number,
  userEstimations:number,
  globalRobotsTrained : number,
  userRobotsTrained : number,
  robotsAccuracyMin: number
  robotsAccuracyMax: number
  robotsAccuracyAverage: number
}

const DashboardPage = () => {
  const [details, setDetails] = useState<null|TDetails>(null)
  const {user} = useContext(UserContext)

  useEffect(()=> {
    POST('user/stats.php',{action:'MAIN_PROFILE',u_id : user?.u_id})
    .then(res => { if(res.status) {setDetails(res.data)} })
    return () => setDetails(null)
  },[user?.u_id])

  return (<Box>
    <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap', width:'100%',}}>
      {user?.u_role === 'ADMIN' && <CardStats icon={<FaUsers size='2em' />} title="User Count" value={details?.userCount} color={blue[300]}/>}
      {user?.u_role === 'ADMIN' &&<CardStats icon={<FaFolderOpen color='yellow' size='2em' />} title="Jobs Created" value={details?.jobCreated} />}
      {user?.u_role === 'ADMIN' && <CardStats icon={<FaDatabase color='cyan' size='2em' />} title="No of Training Sets" value={details?.trainingSets} color={red[300]}/>}
      {user?.u_role === 'ADMIN' && <CardStats icon={<FaGlobe color='green' size='2em' />} title="Global Estimations" value={details?.globalEstimations} color={deepPurple[200]} />}
      <CardStats icon={<FaCalculator color='gray' size='2em' />} title="Estimations (Me)" value={details?.userEstimations} color={deepPurple[200]} />
      {user?.u_role === 'ADMIN' && <CardStats icon={<FaRobot color='purple' size='2em' />} title="Robots Trained" value={details?.globalRobotsTrained} />}
      {user?.u_role === 'ADMIN' && <CardStats icon={<FaRobot color='purple' size='2em' />} title="Robots Trained (Me)" value={details?.userRobotsTrained} />}
      <CardStats icon={<FaBullseye color='red' size='2em' />} title="Accuracy" value={details? `${details.robotsAccuracyMin} - ${details.robotsAccuracyMax} (Avg : ${details.robotsAccuracyAverage})` : null} />
    </Box>
    <Box mt={10} sx={{display:'flex', flexDirection:'column', justifyContent:'start', alignItems:'center'}}>
      <Typography variant='h2'>Flow Assurance Estimation Software</Typography>
      <Typography variant='subtitle1'>Version {process.env.REACT_APP_VERSION}</Typography>
      {/* <Typography variant='h4'>Synergy Sdn. Bhd.</Typography> */}
      <img src='/assets/img/logo/Synergy_icon.png' alt='Synergy Sdn.Bhd' height='150' width={'auto'} />
      <Typography variant='h6'>by Augment Sdn. Bhd.</Typography>
    </Box>

  </Box>)
}

export default DashboardPage