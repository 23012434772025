import React from 'react'
import FormWrapper from '../components/templates/FormWrapper'
import { IStandardForm } from '../components/interface'
import { FormHeader, FormSection, InputSection } from '../components/templates/FormTemplates'
import { Line, Select, TSelectOption } from 'rhf-modular-wrapper'
import * as yup from "yup"
import Avatar from '../components/Avatar'

interface ICreateUserForm extends IStandardForm {}

const userAccountTypes:TSelectOption[] = [
  { label: "Administrator", value: "ADMIN"},
  { label: "Predictors", value: "PREDICTOR"}
]

const yupSchema = yup.object({
  name: yup.string().required("A Name is required").nullable(),
  email : yup.string().required("An Email / Username is required for logging in!").nullable(),
  password : yup.string().required("A Password is required for logging in!").nullable(),
  password2 : yup.string().required("Please Confirm your Password!").oneOf([yup.ref('password'), null], 'Passwords must match').nullable()
})

const CreateUserForm = (props: ICreateUserForm) => {
  return (
    <FormWrapper
      schema={yupSchema}
      submitCaption="Create User!"
      onSubmit={props.onSubmit}>
        <FormHeader>User Information</FormHeader>
        <FormSection>
            <Avatar name="picture" />
          <InputSection>
            <Line name="name" label="Name"  />
          </InputSection>
        </FormSection>
        <FormSection>
          <InputSection>
              <Line type="email" name="email" label="Email" helperText='Used to Login'/>
            </InputSection>
          <InputSection>
              <Select options={userAccountTypes} name="account_type" label="Account Type" helperText="ADMIN - Full Access. Predictor - Only can access Estimators Section" />
          </InputSection>
        </FormSection>
        <FormHeader>Password</FormHeader>
        <FormSection>
          <InputSection>
              <Line name="password" label="Password" type="password"  />
            </InputSection>
          <InputSection>
              <Line name="password2" label="Confirm Password" type="password"  />
            </InputSection>
        </FormSection>
      </FormWrapper>
  )
}

export default CreateUserForm