import React from 'react'
import { components, OptionProps, MultiValueGenericProps } from 'react-select';
import { AsyncSelect, ISelectAsync, TSelectOption } from 'rhf-modular-wrapper';
import { list_users } from '../../../components/utils/loadOptions';
import AvatarShow from '../../../components/Table/components/AvatarShow';

const Option = (props: OptionProps<TSelectOption>) => {  
  return (
    <components.Option {...props} >
      <AvatarShow data={props.data} nameSrc='label' photoSrc='u_photo'/>
    </components.Option>
  );
};


const MultiValueLabel = (props: MultiValueGenericProps<TSelectOption>) => {
  return (
    <AvatarShow data={props.data} nameSrc='label' photoSrc='u_photo'/>
  );
};

interface IUserSelect extends ISelectAsync{
  user_type : ('Admin' | 'Predictor')[]
} 

const UserSelect = (props: IUserSelect) => {
  return (
    <AsyncSelect 
      {...props} 
      loadOptions={(a,b) => list_users(props.user_type,a,b)} 
      rsOptions={{
        isMulti: true,
        components: { Option, MultiValueLabel }
      } as any}
    />
  )
}

export default UserSelect