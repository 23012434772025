import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";


export const FormHeader = styled(Typography)`
  margin:10px 0px;
  font-size:'1.2em';
  font-weight:bold;
  padding: 5px;
  background-color: #d8d7d7;
`
interface IFormSection {
  fd ?: 'column'|'row'
}

export const FormSection = styled(Box)`
  display: flex;
  flex-direction: ${(props: IFormSection) => props.fd ?? 'row'};
  margin-top: 5px;
`
interface IInputSection {
  fg ?: number
}
export const InputSection = styled(Box)`
  flex-grow: ${(props:IInputSection) => props.fg ?? 1};
  padding: 2px 10px;
`