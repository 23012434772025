import React from 'react'
import styled from 'styled-components'

export const ContentHeaders = styled.p`
  background-color: #1976d2;
  text-align:center;
  padding: 10px 5px;
  margin: 5px 0px;
  border-radius: 5px;
  color: white;
`

export const DataHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`
export const DataValue = styled.p`
  font-size: 16px;
`

const LearningViewer = (props:any) => {
  // console.log("[LearningViewer] - props :", props)  
  return (
    <div>
      <p>Data Sucessfully Added!</p>
      <p>Job Name : {props?.job ?? props?.data?.job ?? ''}</p>
      <ContentHeaders>Details</ContentHeaders>
      <DataHeader>Pipe Length (x)</DataHeader>
      <DataValue>{props.data.pipes['fullLength(x)']} m</DataValue>
      <DataHeader>Pipe Average Diameter</DataHeader>
      <DataValue>{Number(props.data.pipes.avg_diameter).toFixed(3)} mm</DataValue>
      <ContentHeaders>Results</ContentHeaders>
      <DataHeader>Calculated Surge Volume</DataHeader>
      <DataValue>{Number(props.data.result.surge_volume).toFixed(3)} m<sup>3</sup></DataValue>
    </div>
  )
}

export default LearningViewer