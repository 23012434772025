import React, { useEffect, useState } from 'react'
import { POST } from '../../../../../../../../components/utils/fetch';
import { ChartComponent, DataLabel, Legend, LineSeries, Tooltip, Inject, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import { Box, Typography } from '@mui/material';

interface IBathymetryPlot {
  id : number
}

const BathymetryPlot = (props:  IBathymetryPlot) => {
  const [ready,setReady] = useState<boolean>(false)
  const [data,setData] = useState<{x:number,y:number}[]>([])

  useEffect(()=>{
    POST("ml/learning.php", {action: "GET_BATHYMETRY", fl_id: props.id})
    .then(response => {
      if (response.status) {setData(response.data.map((x:any) => ({x: Number(x.x), y: Number(x.y)})))}
      setReady(true)
    })
  },[props.id])
  return (ready ? <Box>
    <ChartComponent legendSettings={{visible: true, position: 'Bottom'}} tooltip={{enable:true}} id='charts' primaryXAxis={{title:'Distance (m)'}} primaryYAxis={{title:'Water Depth (m)'}}>
    <Inject services={[LineSeries, Tooltip, DataLabel, Legend]}/>
    <SeriesCollectionDirective>
      <SeriesDirective dataSource={data} xName='x' yName='y' width={2} name='Bathymetry'  type='Line'/>
      <SeriesDirective dataSource={[{x: Math.min(...data.map(x => x.x)), y:0},{x: Math.max(...data.map(x => x.x)), y:0}]} xName='x' yName='y' width={2} name='M.S.L'  type='Line' />
    </SeriesCollectionDirective>
  </ChartComponent> 
    {/* <Plot width={500} height={300}>
      <LineSeries
        data={data}
        xAxis="x"
        yAxis="y"
      />
      <Axis
        id="x"
        min={data.map(x=>x.x).sort((a,b) => a - b)[0]}
        max={data.map(x=>x.x).sort((a,b) => b - a)[0]}
        position="bottom"
        label="Distance (m)"
        displayPrimaryGridLines
      />
      <Axis
        id="y"
        position="left"
        label="Water Depth (m)"
        displayPrimaryGridLines
      />
    </Plot> */}
  </Box>: <Box><Typography variant="h5">Loading....</Typography></Box>)
}

export default BathymetryPlot