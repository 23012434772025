import React, { useCallback } from 'react'
import { IStandardForm } from '../../components/interface'
import { Line } from 'rhf-modular-wrapper'
import { Box, Chip, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import * as yup from "yup"
import FormWrapper from '../../components/templates/FormWrapper'

type TRecalculateSurgeVolumeFormV1Return = {
  massFlow : number,
  glr : number,
  pipe : {
    length : number,
    roughness : number,
    int_diameter : number
  },
  outletLabel : string
}

interface IRecalculateSurgeVolumeForm extends IStandardForm<TRecalculateSurgeVolumeFormV1Return> {
  outlets ?: string[]
}

const yupSchema = yup.object({
  massFlow : yup.number().required('The Mass Flow Value is Required!'),
  glr : yup.number().required('The GLR Value is Required!'),
  pipe : yup.object({
    length : yup.number().required('The Pipe Length is Required!'),
    roughness : yup.number().required('The Pipe Roughness is Required!'),
    int_diameter : yup.number().required('The Pipe Internal Diameter is Required!'),
  }),
  outletLabel : yup.string().required('The Outlet Label is Required!')
})

const RecalculateSurgeVolumeForm = (props: IRecalculateSurgeVolumeForm) => {
  return (
    <FormWrapper {...props} schema={yupSchema} submitCaption='Recalculate Surge Volume'>
      <Box className="w-auto" sx={{display:'flex', }}>
        <Box sx={{paddingRight:'5px',width:'100%'}}><Line name="massFlow" label="Mass Flow (kg/hr)" /></Box>
        <Box sx={{paddingLeft:'5px',width:'100%'}}><Line name="glr" label="GLR (sm3/sm3)" /></Box>
      </Box>
      <Box className="w-auto" sx={{display:'flex', marginTop:'10px'}}>
        <Box sx={{paddingRight:'5px',width:'100%'}}><Line name="pipe.length" label="Pipe Length - x (m)" /></Box>
        <Box sx={{paddingRight:'5px',width:'100%'}}><Line name="pipe.roughness" label="Pipe Roughness (mm)" /></Box>
        <Box sx={{paddingLeft:'5px',width:'100%'}}><Line name="pipe.int_diameter" label="Internal Diameter (mm)" /></Box>
      </Box>
      <Box className="w-auto" sx={{ marginTop:'10px'}}>
        <Line name="dor" label="DOR (m3/hr)" helperText="Draw off rate. Leave at 0 if assuming infinite" />
      </Box>
      <Box className="w-auto">
        <Box>
          <p>Possible Outlet Labels (From TPL File) - Click to Set</p>
          <Box>
            <ClickableChips outlets={props.outlets} />
          </Box>
        </Box>
        <Line name="outletLabel" label="Outlet Label (To get QLT)" />
      </Box>
    </FormWrapper>
  )
}

interface IClickableChips {
  outlets ?: string[]
}

const ClickableChips = (props: IClickableChips) => {
  // console.log("[clickablechips] - props",props)
  const {setValue} = useFormContext()

  const handleClick = useCallback((a:string) => {setValue('outletLabel',a)},[]) // eslint-disable-line

  return <Box sx={{p:3, backgroundColor : 'gainsboro', }}>
    {
      props.outlets && props.outlets?.length > 0 ? 
        props.outlets?.map((outlet) => outlet === 'x_load_x' ? <Typography>Loading Outlet Labels From File....</Typography> : <Chip sx={{m:2}} key={`out-${outlet.replace(' ','')}`} label={outlet} onClick={()=>handleClick(outlet)} />) :
        <Typography>No QLT Outlet Found!</Typography>
    }
  </Box>
}

export default RecalculateSurgeVolumeForm