import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import ClearIcon from '@mui/icons-material/Clear';
import Box from "@mui/material/Box/Box"
import { POST } from '../../../../../components/utils/fetch';
import { toast } from 'react-toastify';
import parseHandlerFunction from '../../../../../components/ParseHandler/parseHandlerFunction';

type ActionTypes = 'delete'|'delete_cache'|'regenerate_cache'

const FileActions = (props: any) => {
  const clickHandler = async (e: any, type: ActionTypes) => {
    e.preventDefault(); e.stopPropagation();
    const confirmTitles:Record<ActionTypes,string> = {
      'delete' : `Confirm Delete for file ${props?.fi_name ?? '' } ?`,
      'delete_cache' : `Confirm Delete cache for file ${props?.fi_name ?? '' } ?`,
      'regenerate_cache' : `Regenerate Cache for file ${props?.fi_name ?? ''} ?`,
    }

    if (window.confirm(confirmTitles[type])) {
      switch (type) {
        case 'delete':
          POST('file/index.php',{action: 'DELETE_FILE', file: props.fi_id, fileName : props.fi_name})
          .then((res) => {
            if (res.status){
              toast.success(`File ${props?.fi_name ?? ''} deleted successfully!`)
              if (props.tableRef.current !== null && props.tableRef.current.refresh !== undefined) {
                props.tableRef.current.refresh()
              }
            } else {
              toast.error(`File ${props?.fi_name ?? ''} could not be deleted! Error: ${res.data}`)          
            }
          })
        break;
        case 'delete_cache':
          POST('file/index.php',{action: 'REMOVE_CACHE_RESUME', file_name: props.fi_name})
          .then((res) => {
            if (res.status){
              toast.success(`File cache for ${props?.fi_name ?? ''} deleted successfully!`)
              if (props.tableRef.current !== null && props.tableRef.current.refresh !== undefined) {
                props.tableRef.current.refresh()
              }
            } else {
              toast.error(`File cache for ${props?.fi_name ?? ''} could not be deleted! Error: ${res.data}`)          
            }
          })
        break;
        case 'regenerate_cache':          
          await parseHandlerFunction(props.fi_name)
          if (props.tableRef.current !== null) {
            props.tableRef.current.refresh()
          }
          break;
      }
    }
  }

  return (
    <div style={{display:'flex', flexDirection:'row',justifyContent:'flex-start',}}>      
      <Tooltip title="Delete">
        <IconButton onClick={(e) => clickHandler(e, 'delete')} aria-label="Delete">
          <DeleteIcon color='error' />
        </IconButton>
      </Tooltip>
      <Tooltip title="Regenerate Cache">
        <IconButton onClick={(e) => clickHandler(e, 'regenerate_cache')} aria-label="Regenerate Cache">
          <CachedIcon color='primary' />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Caching Resume (Click when Parsing doesnt seem to work">
        <IconButton onClick={(e) => clickHandler(e, 'delete_cache')} aria-label="Delete Cache">
          <Box sx={{position:'relative', width:'24px',height:'24px'}}>
            <CachedIcon sx={{fontSize:'1em'}} />
            <ClearIcon color='error' sx={{position:'absolute', fontSize:'1em', top:0, left:0}}/>
          </Box>
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default FileActions