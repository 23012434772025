import React from 'react'
import { Tooltip, IconButton } from '@mui/material'
import { FaDoorClosed, FaHardDrive, FaTrash, FaUserPen } from 'react-icons/fa6'

interface IUserAction {
  [key:string] : any,
  setActionType: Function
}

const UserAction = (props: IUserAction) => {
  return (
    <div>
      <Tooltip 
        onClick={()=>props.setActionType({user: props, type:'change_pass'})} 
        title="Change Password">
            <IconButton color='primary'>
              <FaDoorClosed />
            </IconButton>
      </Tooltip>
      <Tooltip 
        onClick={()=>props.setActionType({user: props, type:'update'})} 
        title="Update User">
            <IconButton color='info'>
              <FaUserPen />
            </IconButton>
      </Tooltip>
      {props.u_role !== 'ADMIN' && <Tooltip 
        onClick={()=>props.setActionType({user: props, type:'change_permission'})} 
        title="Change ML Permissions">
            <IconButton color='info'>
              <FaHardDrive />
            </IconButton>
      </Tooltip>}
      <Tooltip 
        onClick={()=>props.setActionType({user: props, type:'delete'})} 
        title="Delete User">
            <IconButton color='warning'>
              <FaTrash />
            </IconButton>
      </Tooltip>
    </div>
  )
}

export default UserAction