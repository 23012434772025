import React from 'react'
import { FaEye, FaDumbbell } from "react-icons/fa6"
import { Tooltip, IconButton } from '@mui/material'

interface IMLActions {
  setChosenML : Function,
  [key: string] : any
}

const MLActions = (props: IMLActions) => {
  return (
    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',width:'100%'}}>
      <Tooltip onClick={()=>props.setChosenML({ml: props, type:'view'})} title="View Details"><IconButton color='primary'><FaEye /></IconButton></Tooltip>
      <Tooltip onClick={()=>props.setChosenML({ml: props, type:'retrain'})} title="Retrain"><IconButton color='warning'><FaDumbbell /></IconButton></Tooltip>
    </div>
  )
}

export default MLActions