import React from 'react'
import { ContentHeaders, DataHeader, DataValue } from './LearningViewer'

const EstimationViewer = (props: any) => {
  // console.log('EstimationViewer', props)
  return (
    <div>
      <ContentHeaders>Details</ContentHeaders>          
      <DataHeader>Surge Volume</DataHeader>
      <DataValue>{Number(props.data?.surgeVolume ?? 0).toLocaleString()} m3</DataValue>
      <DataHeader>Confidence Value (R-Squared)</DataHeader>
      <DataValue>{props.data?.accuracy ? `${(100 * Number(props.data?.accuracy)).toFixed(2)} %` : 0}</DataValue>
    </div>
  )
}

export default EstimationViewer