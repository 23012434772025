import React, { useState,  useContext } from 'react'
import {  Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { Grid } from '@syncfusion/ej2-react-grids'
import { POST } from '../../../../../../../components/utils/fetch'
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../../../../../../../App'
import { toast } from 'react-toastify'
import PopperTable from './components/PopperTable'
import FormWrapper from '../../../../../../../Forms/components/templates/FormWrapper'
import { TStandardPromise } from '../../../../../../../Forms/components/interface'
import { Line } from 'rhf-modular-wrapper'
import UserSelect from '../../../../../../../Forms/components/UserSelect'
import ValidationStation from './components/ValidationStation'


interface IConfirmTrainModal {
  show: boolean,
  type: 'Pigging'| 'SteadyState' | 'SlugTracking',
  onHide: () => void,
  tableRef : React.RefObject<Grid>,
  isAll : boolean
  parent_ml ?: number,
  version ?: number | string
  name ?: string
}

const ConfirmTrainModal = (props: IConfirmTrainModal) => {
  const [isTraining, setIsTraining] = useState(false)
  const {user} = useContext(UserContext)

  const sendTraining = (a:any) => new Promise<TStandardPromise>(async (resolve, reject) => {
    setIsTraining(true)
    const job_ids = (props.tableRef.current?.selectionModule.getSelectedRecords() ?? []).map((x:any) => x.fl_id)    
    const toastID = toast.loading("Sending Training Data...",{isLoading: true, autoClose: false})
    POST('ml/learning.php',{
      action:'TRAIN_ML',
      name : a.name,
      version : props.version ? Number(props.version) + 1 :'New',
      ml_id: props.parent_ml,
      ids:props.isAll? 'All' :job_ids,
      type:props.type, 
      allowedUsers : a.allowed,
      u_id : user === null ? 0: user.u_id
    }).then((response) => { 
      if (response.status) {
        toast.update(toastID, {
          type: 'success',
          render : 'Learning Completed!',
          isLoading: false,
          autoClose: 2000
        })
        props.onHide()
      } else {
        toast.update(toastID, {
        type: 'error',
        render : "Something went Wrong!",
        isLoading: false,
        autoClose: 2000
      })
        props.onHide()
      }

      setIsTraining(false)
    })    
  })
    

  return (
    <Dialog open={props.show} onClose={()=>props.onHide()} maxWidth="xl" fullWidth>
      <DialogTitle> Set Selection</DialogTitle>
      <IconButton
          aria-label="close"
          onClick={()=>props.onHide()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      <DialogContent sx={{my:5, paddingBottom:'200px'}}>
        {props.show && <Box sx={{my:2}}>
          <Typography>No of sets selected : {props.isAll ? 'All' : (props.tableRef.current?.selectionModule.getSelectedRecords() ?? []).length}</Typography>
          <Box sx={{my:2}}>
            <Typography sx={{my:2, backgroundColor:'powderblue',py:2}} textAlign={'center'} variant="h5">Learning IDs (Hover for details)</Typography>
            {
              props.isAll ? <Typography variant="h6">All Learning Rows for {props.type === 'SlugTracking' ? 'Slug Tracking' : props.type === 'SteadyState' ? 'Steady State' : 'Pigging'}</Typography> : 
              (props.tableRef.current?.selectionModule.getSelectedRecords() ?? []).map((x:any,i:number) => <PopperTable key={`ctm-jb-${i}`} data={x} />)
            }
          </Box>
          <ValidationStation type={props.type} rows={props.tableRef.current?.selectionModule.getSelectedRecords() ?? []} isAll={props.isAll} />
          <Box>
            {
              isTraining ? 
              <Box width='100%' overflow={'auto'} px={2} py={4}>
                <Typography>
                  Machine learning in progress....please hold on...
                </Typography>
              </Box> : <FormWrapper onSubmit={sendTraining} submitCaption='Train Bot'>
                <Line defaultValue={props.name} disabled={props.name !== undefined} name="name" label="Model Name (Tag)" helperText='A simple tag for the model' />
                <UserSelect defaultOptions cachedOptions loadOptions={()=>{}} user_type={['Predictor']} rsOptions={{isMulti:true}} name="allowed" label="Allowed Users" helperText='Users allowed to use this model. Only applies to Predictor users. Admins may view all models' />
              </FormWrapper>
            }
            {/* <Button variant="contained" onClick={()=>sendTraining()}>Train Bot</Button> */}
          </Box>
        </Box>}
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmTrainModal