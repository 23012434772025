import React, {useState, useRef} from 'react'
import {Avatar as AvatarIcon, Box, Typography} from "@mui/material"
import { useFormContext } from 'react-hook-form'

const Avatar = (props: any) => {
  const inputRef = useRef<HTMLInputElement>(null)
  
  const [hover, setHover] = useState(false)

  const methods = useFormContext()

  const image = methods.watch(props.name)
  // console.log('Avatar', image)

  const getPicture = (a:any) => {    
    const file:File = a.target.files[0]
    if (file.type.includes("image")) { 
      methods.setValue(props.name, file) 
    }    
  }
  
  return (
    <Box onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} sx={{width: 56, height: 56, display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',position:'relative'}}>
      <AvatarIcon  sx={{ width: 56, height: 56 }} src={image && image.name ? URL.createObjectURL(image) : undefined} />
      <input ref={inputRef} type="file" style={{opacity:0,visibility:'hidden'}} onChange={getPicture} />
      <Box onClick={()=>inputRef.current ? inputRef.current.click() : null} sx={{display:'flex',cursor:'pointer',opacity: hover ? 1:0, transition:'0.3s ease-in-out', borderRadius:'56px', alignItems:'center', justifyContent:'center', backgroundColor:'#0000001a', position:'absolute', top:0, left:0, height:'100%',width:'100%'}}>
        <Typography variant='caption' textAlign={'center'}>Choose</Typography>
      </Box>
    </Box>
  )
}

export default Avatar