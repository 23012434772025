import React, {useContext} from 'react'
import { Box, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import styled from '@emotion/styled'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaFolderOpen, FaFolderPlus, FaGear, FaListCheck, FaSchool, FaUsers } from "react-icons/fa6"
import { UserContext } from '../../../../App';

const SidebarHeaders = styled(Typography)`
  font-size:20;
  font-weight: bold;
  width: 100%;
  border-bottom: 1px dotted #6d6d6d;
  padding : 5px 1px;
  margin-bottom:4px;
`
const SidebarSubHeaders = styled(Typography)`
  font-size: 10px;
  font-weight:200;
  margin-top: 2px;
  margin-bottom: 4px;
`

type TActive = {active:boolean}
const HeaderButtons = styled(Button)`
  margin: 2px 2px;
  width: 100%;
  border-radius: 0 0 0 0;
  transition: all 0.5s ease-in-out;
  /* border-bottom: ${(props:TActive) => props.active ? '1px solid' : ''}; */
   ${(props:TActive) => props.active? 'background-color: #bebebe;' : ''}
   ${(props:TActive) => props.active? 'color: #3051a1;' : ''}
  justify-content: flex-start;

  &:hover {
    border-bottom: 1px solid #6d6d6d;
  }
`

const SideBar = () => {
  const {user} = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Box sx={{px:2,pt:2, mr:3, width:200, height:'100%', backgroundColor: 'gainsboro', overflowY:'auto'}}>
      <SidebarHeaders>ESTIMATION</SidebarHeaders>
      <HeaderButtons active={location.pathname === '/fa/estimation/new'} variant="text" onClick={()=>navigate('/fa/estimation/new')} startIcon={<AddIcon/>} >New</HeaderButtons>
      <HeaderButtons active={location.pathname === '/fa/estimation/history'} variant="text" onClick={()=>navigate('/fa/estimation/history')} startIcon={<HistoryIcon/>}>History</HeaderButtons>

      {
        user !== null && user.u_role === 'ADMIN' && <>
      <SidebarHeaders >LEARNING</SidebarHeaders>
      <SidebarSubHeaders>ADDING ENTRIES</SidebarSubHeaders>
      <HeaderButtons active={location.pathname === '/fa/learning/new/single'} variant="text" onClick={()=>navigate('/fa/learning/new/single')} startIcon={<AddIcon/>}>Add Single</HeaderButtons>
      {/* <HeaderButtons active={location.pathname === '/fa/learning/new/batch'} variant="text" onClick={()=>navigate('/fa/learning/new/batch')} startIcon={<AddIcon/>}>Add Batch</HeaderButtons> */}
      <HeaderButtons active={location.pathname === '/fa/learning/history'} variant="text" onClick={()=>navigate('/fa/learning/history')} startIcon={<HistoryIcon/>}>History</HeaderButtons>
      <SidebarSubHeaders>MACHINE LEARNING</SidebarSubHeaders>
      <HeaderButtons active={location.pathname === '/fa/learning/train/view'} variant="text" onClick={()=>navigate('/fa/learning/train/view')} startIcon={<FaGear />}>Models</HeaderButtons>
      <HeaderButtons active={location.pathname === '/fa/learning/train/new'} variant="text" onClick={()=>navigate('/fa/learning/train/new')} startIcon={<FaSchool/>}>Teach AI</HeaderButtons>

      <SidebarHeaders>FILES</SidebarHeaders>
      <HeaderButtons active={location.pathname === '/files/new'} variant="text" onClick={()=>navigate('/files/new')} startIcon={<FaFolderPlus/>}>New</HeaderButtons>
      <HeaderButtons active={location.pathname === '/files/view/genkey'} variant="text" onClick={()=>navigate('/files/view/genkey')} startIcon={<FaFolderOpen />}>View</HeaderButtons>
 
        </>
      }

      <SidebarHeaders>Jobs</SidebarHeaders>
      {user !== null && user.u_role === 'ADMIN' && <HeaderButtons active={location.pathname === '/user/create'} variant="text" onClick={()=>navigate('/job/add')} startIcon={<FaFolderPlus />}>Add Job</HeaderButtons>}
      <HeaderButtons active={location.pathname === '/user/create'} variant="text" onClick={()=>navigate('/job/view')} startIcon={<FaListCheck />}>View Jobs</HeaderButtons>

      <SidebarHeaders>USER</SidebarHeaders>
      {user !== null && user.u_role === 'ADMIN' && <HeaderButtons active={location.pathname === '/user/create'} variant="text" onClick={()=>navigate('/user/create')} startIcon={<PersonAddIcon />}>Add</HeaderButtons>}
      {user !== null && user.u_role === 'ADMIN' && <HeaderButtons active={location.pathname === '/user/create'} variant="text" onClick={()=>navigate('/user/list')} startIcon={<FaUsers />}>View ALl</HeaderButtons>}
      <HeaderButtons active={location.pathname === '/user/profile'} variant="text" onClick={()=>navigate('/user/profile')} startIcon={<AccountCircleIcon/>}>Profile</HeaderButtons>
      {/* <HeaderButtons active={location.pathname === '/user/settings'} variant="text" onClick={()=>navigate('/user/settings')} startIcon={<SettingsIcon/>}>Settings</HeaderButtons> */}
      <HeaderButtons active={location.pathname === '/user/logout'} variant="text" onClick={()=>navigate('/user/logout')} startIcon={<LogoutIcon/>}>Logout</HeaderButtons>

      

    </Box>
  )
}

export default SideBar