import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AsyncSelect, Line, Radiobox, TSelectOption } from "rhf-modular-wrapper";
import { POST } from "../../../components/utils/fetch";
import { FormHeader, FormSection, InputSection } from "../../components/templates/FormTemplates";
import { IStandardForm, TFAMode } from "../../components/interface";
import SelectFileOrUploadv1 from "../../SelectFileOrUpload/v1";
import * as Yup from "yup";
import { useFormContext } from "react-hook-form";
import FormWrapper from "../../components/templates/FormWrapper";
import { Chip, Typography } from "@mui/material";
import PressureConverterInput, { convertPressure, setPressure } from "../../components/Converter/Pressure";

export type TFALearningReturnObject = {
  tag: string;
  job: TSelectOption;
  mode: TFAMode;
  tab: TSelectOption;
  genkey: TSelectOption;
  tpl: TSelectOption;
  data : {
    glr : number,
    massflow : number,
    pipe_x_len : number,
    pipe_roughness : number,
    pipe_id : number,
    position_labels_tpl : string,
    p_in : number,
    'p_in-unit' : TSelectOption,
    p_out : number,
    'p_out-unit' : TSelectOption,
    p_drop : number,
    'p_drop-unit' : TSelectOption,
  }
};

interface IFALearningV2 extends IStandardForm<TFALearningReturnObject> {}

const FALearningV2Schema = Yup.object({
  job: Yup.mixed().test(
    "is-option",
    "PLEASE CHOOSE OR CREATE JOB NAME",
    (val) => val?.value !== undefined
  ),
  mode: Yup.string().is(["slugtracking", "pigging", "steadystate"]),
  tab: Yup.mixed().test(
    "is-option",
    "PLEASE CHOOSE A .TAB FILE",
    (val) => val?.value !== undefined
  ),
  genkey: Yup.mixed().test(
    "is-option",
    "PLEASE CHOOSE A .GENKEY FILE",
    (val) => val?.value !== undefined
  ),
  tpl: Yup.mixed().test(
    "is-option",
    "PLEASE CHOOSE A .TPL FILE",
    (val) => val?.value !== undefined
  ),
  data : Yup.object({
    glr : Yup.number().nullable().required("A VALUE IS REQUIRED"),
    massflow : Yup.number().nullable().required("A VALUE IS REQUIRED"),
    pipe_x_len : Yup.number().nullable().required("A VALUE IS REQUIRED"),
    pipe_id : Yup.number().nullable().required("A VALUE IS REQUIRED"),
    position_labels_genkey : Yup.string().nullable().required("A VALUE IS REQUIRED"),
    position_labels_tpl : Yup.string().nullable().required("A VALUE IS REQUIRED"),

  })
});

const FALearningV2 = (props: IFALearningV2) => {
  const loadJobs = useCallback(
    (key: string, callback: (a: TSelectOption[]) => void) =>
      new Promise((resolve, reject) => {
        POST<TSelectOption[]>("job/index.php", {
          action: "GET_JOB_SELECT",
          key: key ?? "",
        }).then((response) => {
          // console.log("[loadJobs] - ", response);
          callback(response.data);
        });
      }),
    []
  );

  const defaultValues = useMemo(
    () => ({
      mode: "slugtracking",
      ...props.defaultValues,
    }),
    [props.defaultValues]
  );

  return ( 
    <FormWrapper submitCaption="Add Learning Data" schema={FALearningV2Schema} onSubmit={props.onSubmit} defaultValues={defaultValues as TFALearningReturnObject} >
      <FormHeader>General Input</FormHeader>
      <FormSection fd="column">
        <FormSection>
          <InputSection>
            <Line
              name="tag"
              label="Learning Input Name (Tag)"
              helperText="A Name or Tag for this dataset. Not required"
            />
          </InputSection>
          <InputSection>
            <AsyncSelect
              defaultOptions
              label="Select or Create Job"
              helperText="The Job that this Data is linked to"
              name="job"
              loadOptions={loadJobs}
              isCreatable
            />
          </InputSection>
        </FormSection>
        <FormSection>
          <InputSection>
            <Radiobox
              orientation="horizontal"
              name="mode"
              label="Mode"
              helperText="Select The Type of Estimation"
              options={[
                { value: "slugtracking", label: "Slug Tracking" },
                { value: "pigging", label: "Pigging" },
                { value: "steadystate", label: "Steady State" },
              ]}
            />
          </InputSection>
        </FormSection>
      </FormSection>
      <FormHeader>Input Files</FormHeader>
      <FormSection>
        <InputSection>
          <SelectFileOrUploadv1
            name="tab"
            selectProps={{
              label: "Tab File",
              helperText: "Choose or Upload a .tab file!",
            }}
            type="tab"
          />
        </InputSection>
        <InputSection>
          <SelectFileOrUploadv1
            name="genkey"
            selectProps={{
              label: "GENKEY File",
              helperText: "Choose or Upload a .genkey file!",
            }}
            type="genkey"
          />
        </InputSection>
      </FormSection>
      <FormHeader>Output Files</FormHeader>
      <FormSection>
        <InputSection>
          <SelectFileOrUploadv1
            name="tpl"
            selectProps={{
              label: "TPL File",
              helperText: "Choose or Upload a .tpl file!",
            }}
            type="tpl"
          />
        </InputSection>
      </FormSection>
      <FormHeader>Input Parameters</FormHeader>
      <InputParametersSection />
      <FormSection fd="column">
        <p style={{fontWeight:'bold',color:'red'}}>Note: Assumes DOR of 0. Regenerate in /fa/learning/history to vary the DOR</p>
      </FormSection>
    </FormWrapper>
  );
};

const InputParametersSection = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [lastTpl, setLastTpl] = useState(undefined) // eslint-disable-line
  const [lastTab, setLastTab] = useState(undefined) // eslint-disable-line
  const [lastGenkey, setLastGenkey] = useState(undefined) // eslint-disable-line

  const [tplPostionLabels, setTplPostionLabels] = useState<string[]>([])
  const [genkeyPostionLabels, setGenkeyPostionLabels] = useState<string[]>([])

  const {watch, setValue, getValues} = useFormContext()
  const tpl = watch('tpl')
  const tab = watch('tab')
  const genkey = watch('genkey')
  
  useEffect(()=>{
    if (genkey !== undefined && genkey !== null/* && (lastGenkey !== genkey.label) */) {
      setLoading(true)
      const dataVal = getValues('data')
      POST('file/index.php',{file_name: genkey?.label ?? '', action: 'GET_LEARNING_PARAMS'})
      .then(response => {
        setLoading(false)
        // console.log("[response-genkey] - ",response)
        const {massflow, pipe_len,pipe_roughness,pipe_id,position_labels, pressureOut, pressureOutUnit} = response.data
        setLastGenkey(genkey.label)
        setGenkeyPostionLabels((Array.isArray(position_labels) ? position_labels : [position_labels]).map(x => x.toLowerCase()))
        setValue('data',{
          ...dataVal,
          glr : dataVal?.glr ?? '',
          massflow : massflow ?? dataVal.massFlow ?? '',
          pipe_x_len : pipe_len ?? dataVal?.pipe_x_len ??'',
          pipe_roughness : pipe_roughness ?? dataVal?.pipe_roughness ??'',
          pipe_id : pipe_id ?? dataVal?.pipe_id ?? '',
          position_labels_genkey : (Array.isArray(position_labels) ? position_labels.pop() : dataVal?.position_labels ?? '').toLowerCase(),
        })

        setPressure(pressureOut, pressureOutUnit, 'data.p_out', setValue)
      }).catch(err => {
        setLoading(false)
      })

    }
  },[genkey]) //eslint-disable-line

  useEffect(()=>{
    if (tpl !== undefined && tpl !== null/* && (lastTpl !== tpl.label) */) {
      setLoading(true)
      const dataVal = getValues('data')
      POST('file/index.php',{file_name: tpl?.label ?? '', action: 'GET_LEARNING_PARAMS'})
      .then(response => {
        setLoading(false)        
        // console.log("[response-tpl] - ",response)
        setLastTpl(tpl.label)
        const {position_labels, pressures} = response.data
        setTplPostionLabels((Array.isArray(position_labels) ? position_labels : [position_labels]).map(x=>x.toLowerCase()))
        if (dataVal?.position_labels_genkey !== undefined) {
          if ((position_labels as string[]).map(x=>x.toLowerCase()).includes(dataVal.position_labels_genkey)) {
            setValue('data.position_labels_tpl',dataVal.position_labels_genkey) 
          } else {
            setValue('data.position_labels_tpl','') 
          }
        }
        setValue('data.pressures',pressures)

      }).catch(err => {
        setLoading(false)
      })
    }
  },[tpl, genkey]) //eslint-disable-line

  useEffect(()=>{
    if (tab !== undefined && tab !== null/*  && (lastTab !== tab.label) */) {
      setLoading(true)
      const dataVal = getValues('data')
      POST('file/index.php',{file_name: tab?.label ?? '', action: 'GET_LEARNING_PARAMS'})
      .then(response => {
        setLoading(false)
        // console.log("[response-tab] - ",response)
        const {glr} = response.data
        setLastTab(tab.label)
        setValue('data.glr',glr ?? dataVal.glr ?? '')
      }).catch(err => {
        setLoading(false)
      })
    }
  },[tab]) //eslint-disable-line

  const handleChoosePositionLabel = (positionLabel: string) => {
    setValue('data.position_labels_tpl',positionLabel.trim())

    const pos = positionLabel.substring(0, positionLabel.lastIndexOf("_"))

    const pressures = getValues('data.pressures')
    if (pressures !== undefined && pressures !== null && typeof pressures === 'object') {
      const pressureKeys = Object.keys(pressures)
      const keyOfPressure = pressureKeys[pressureKeys.findIndex((v) => v.toLowerCase() === pos.toLowerCase())]
      const pressure = pressures[keyOfPressure]
      const unit = pressures[`${keyOfPressure}_unit`]
      console.group("Setting Pressures")
      console.log('Pressures : ',pressures)
      console.log('Pos : ',keyOfPressure)
      console.log('Pressure : ',pressure)
      console.log('Unit : ',unit)
      console.groupEnd()
      setPressure(Number(pressure),unit,'data.p_in', setValue)
    }
  }

  const [p_in, p_out] = watch(['data.p_in', 'data.p_out'])
  useEffect(() => {
    if (p_in !== undefined && p_in !== null && p_out !== undefined && p_out !== null) {
      const POut = convertPressure(p_out, getValues('data.p_out-unit')?.value ?? 'pa', 'barg')
      const PIn = convertPressure(p_in, getValues('data.p_in-unit')?.value ?? 'pa', 'barg')

      
      console.log('P_in ',p_in, getValues('data.p_in-unit'))
      console.log('P_out ',p_out, getValues('data.p_out-unit'))
      console.log('P_in converted ; ',PIn)
      console.log('P_out converted ; ',POut)

      setPressure(Number(PIn) - Number(POut), 'barg','data.p_drop',setValue)
    }
  },[p_in, p_out]) //eslint-disable-line


  return loading ? <Typography>Loading Data...</Typography> :  <div>
    <FormSection>
      <InputSection><Line type="number" name="data.glr" label="GLR (sm3/sm3)" helperText="GLR Value in sm3/sm3. From tab files" /> </InputSection>
      <InputSection><Line type="number" name="data.massflow" label="Mass Flow (kg/hr)" helperText="Mass Flow Value in kg/hr. From genkey files" /> </InputSection>
      <InputSection><Line type="number" name="data.pipe_x_len" label="Pipe Length (x) (m)" helperText="Pipe X-axis Length in mm. From genkey files" /> </InputSection>
      {/* <InputSection><Line type="number" name="data.pipe_roughness" label="Pipe Roughness (mm)" helperText="Pipe Roughness in mm. From genkey files" /> </InputSection> */}
      <InputSection><Line type="number" name="data.pipe_id" label="Pipe ID (mm)" helperText="Pipe Internal Diameter in mm. From genkey files" /> </InputSection>
    </FormSection>
    <FormSection>
      <InputSection><PressureConverterInput helperText="Pressure Into System. Taken from TPL" label="Pressure In" name='data.p_in' /></InputSection>
      <InputSection><PressureConverterInput helperText="Pressure Out of System. Taken from Genkey" label="Pressure Out" name='data.p_out' /></InputSection>
      <InputSection><PressureConverterInput helperText="Pressure In - Pressure Out" label="Pressure Drop" name='data.p_drop' /></InputSection>
    </FormSection>
    <FormSection>
      <InputSection>
        {/* <Line disabled name="data.position_labels_genkey" label="Outlet / Landing Label (genkey)" helperText="Outlet / Landing Label from genkey File (Should Match tpl File's Outlet. Can ignore this field)"/> */}
        <p>Available Labels from Genkey File</p>
        <ul>
          {genkeyPostionLabels.map((x) => <li key={`pl-gk-${x.replace(' ','')}`}>{x}</li>)}
        </ul>
      </InputSection>
      <InputSection>
        <Line name="data.position_labels_tpl" label="Outlet / Landing Label (tpl)" helperText="Outlet / Landing Label from tpl File (Should Match genkey File's Outlet)"/>
        <p>Available Labels from TPL File</p>
        <ul>
          {tplPostionLabels.map((x) => <li key={`pl-tpl-${x.replace(' ','')}`}><Chip sx={{margin:'5px 2px'}} label={x} variant="outlined" onClick={()=>handleChoosePositionLabel(x)} /></li>)}
        </ul>
      </InputSection>
    </FormSection>
  </div>
}

export default FALearningV2;
