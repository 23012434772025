import React from 'react'
import { TMLReturn } from '../../../../../../../..'
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, ScatterSeries, AxisModel } from '@syncfusion/ej2-react-charts';

interface ISurgePlot {
  data : TMLReturn | null,
  plotData : {surge: number, dor: number}[]
}

const SurgePlot = (props: ISurgePlot) => {
  // const [data ,setData] = React.useState([])

  // React.useEffect(()=>{
  //   if (props.data !== null) {
  //     POST("ml/learning.php",{action: 'GET_SURGE_PLOT', p_id : props.data.fl_id})
  //     .then(response => {
  //       if (response.data.status) {
  //         setData(response.data.data.map((x:any) => ({surge : Number(x.surge), dor : Number(x.dor)})))
  //       }
  //     })
  //   }
  // },[props.data])

  const primaryxAxis: AxisModel = {
    title: 'Draw-off Rate (m3)', edgeLabelPlacement: 'Shift', 
  };
  const primaryyAxis: AxisModel = {
      title: 'Surge (m3)', rangePadding: 'None'
  };
  const marker = { width: 15, height: 15 };
  
  return (
    <ChartComponent id='s_vs_dor_scatter' 
      primaryXAxis={primaryxAxis}
      primaryYAxis={primaryyAxis} 
      title='Surge vs DOR'
      tooltip={{
        enable: true,
        template: ("<table style='padding:5px;background-color:white;'><tbody><tr><th>DOR</th><td>${x}</td></tr><tr><th>Surge</th><td>${y}</td></tr></tbody></table>") //eslint-disable-line
      }}
    >
      <Inject services={[ScatterSeries, Legend, Tooltip, DataLabel, Category]}/>
      <SeriesCollectionDirective>
        <SeriesDirective 
          dataSource={props.plotData} 
          xName='dor' 
          yName='surge' 
          type='Scatter' 
          marker={marker}
        >
        </SeriesDirective>
      </SeriesCollectionDirective>
    </ChartComponent>
  )
}

export default SurgePlot